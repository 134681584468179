import React from "react";
import axios from "axios";
import "./LoginPage.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Box,
  Paper,
  Grid,
  Avatar,
  CssBaseline,
  InputAdornment,
} from "@mui/material";
import AdminPanelSettingsSharpIcon from "@mui/icons-material/AdminPanelSettingsSharp";
import sha512 from "crypto-js/sha512";
import { useNavigate } from "react-router-dom";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import KeyIcon from "@mui/icons-material/Key";
import { useAuth } from "../bms-authentication/AuthContext";
import Footer from "../bms-index/Footer";
import Header from "../bms-index/Header";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import LoginIcon from '@mui/icons-material/Login';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loginBtn: {
    background: 'linear-gradient(100deg, rgb(42, 0, 46) 0%, #100029 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #06003d 0%, #320036 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
}));

const LoginPage = () => {
  const { setApiToken, setUserId, state, setErrorMessage } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const handleLogin = async (values, props) => {
    const username = values.username;

    const hashedPassword = sha512(values.hash).toString();

    const requestBody = JSON.stringify({
      hash: hashedPassword,
    });

    const url = `https://api.grvcle.in/bms/v1/admin/${username}/login`;

    try {
      const response = await axios.post(url, requestBody);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      if (response.data.status === "ok") {
        setApiToken(response.data.data["api-token"]);
        setUserId(username);
        navigate(`/management-index`);
      } else {
        const message = response.data.message.toUpperCase();
        setErrorMessage(message || "Invalid username or password");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred during login.");
    }

    setTimeout(() => {
      props.resetForm();
      props.setSubmitting(false);
    }, 3000);
  };

  //Form Validation By Using Formik And Yup
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    hash: Yup.string().required("Password is required"),
  });

  //Styles
  const paperStyle = {
    padding: 20,
    width: "310px",
    margin: "170px auto",
  };

  const AvatarStyle = {
    background: "linear-gradient(180deg, rgb(0, 5, 80) 100%, #000000 100%",
    fontSize: "26px",
  };

  const IconStyle = {
    fontSize: "23px",
    color: "black",
  };

  const divStyle = { margin: "10px 0px 20px 0px" };

  return (
    <div>
      <Header />
      <Paper elevation={24} style={paperStyle}>
        <CssBaseline />
        <div
          style={{
            color: "red",
            textAlign: "center",
            margin: "2px,0px,10px,0px",
          }}
        >
          <p>{state.errorMessage}</p>
        </div>
        <Grid align={"center"}>
          <Avatar style={AvatarStyle} sx={{ width: 33, height: 33 }}>
            <AdminPanelSettingsSharpIcon sx={{ fontSize: "25px" }} />
          </Avatar>
          <Typography
            component="h3"
            variant="h4"
            display={"flex"}
            justifyContent={"center"}
            padding={"10px"}
            margin={"5px auto"}
            fontSize={"26px"}
            fontFamily={"revert-layer"}
            fontWeight={"bold"}
          >
            LOGIN
          </Typography>
        </Grid>
        <Box component="div">
          <Formik
            initialValues={{ username: "", hash: "", rememberMe: false }}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {(props) => (
              <Form>
                <div style={divStyle}>
                  <Field
                    margin="dense"
                    type="text"
                    name="username"
                    label="Username"
                    placeholder="Enter Username"
                    id="input-with-icon-textfield1"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonPinCircleIcon sx={IconStyle} />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                    color={props.values.username ? "secondary" : "error"}
                    as={TextField}
                    helperText={<ErrorMessage name="username" />}
                  />
                </div>
                <div style={divStyle}>
                  <Field
                    margin="dense"
                    type="password"
                    name="hash"
                    label="Password"
                    placeholder="Enter Password"
                    id="input-with-icon-textfield2"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon sx={IconStyle} />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                    color={props.values.hash ? "secondary" : "error"}
                    as={TextField}
                    helperText={<ErrorMessage name="hash" />}
                  />
                </div>
                <div style={divStyle}>
                  <FormControlLabel
                    control={<Checkbox name="rememberMe" sx={{
                      color: grey[900],
                      "&.Mui-checked": {
                        color: grey[900],
                      },
                    }} />}
                    label="Remember me"
                  />
                </div>
                <div style={divStyle}>
                  <Button
                    sx={{
                      margin: "8px 0",
                      fontSize: "19px",
                      backgroundColor: "black",
                    }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    margin="dense"
                    disabled={props.isSubmitting}
                    className={classes.loginBtn}
                  >
                    {props.isSubmitting ? (
                      <CircularProgress size={24} sx={{color:"white"}} />
                    ) : (
                      <Box sx={{display:"flex"}}><LoginIcon fontSize="small" sx={{mx:"2px",marginTop:"3px"}}/><Typography sx={{fontSize:"19px"}}>Login</Typography></Box>
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
      <Footer />
    </div>
  );
};

export default LoginPage;
