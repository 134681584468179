import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {
  AlertTitle,
  Box,
  ButtonGroup,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, PersonAdd } from "@mui/icons-material";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import MuiAlert from "@mui/material/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    background: "linear-gradient(90deg, #000000 0%, #130036 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #000000 0%, #004e00 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
}));

const SetZoneUser = ({ onClose, zoneId, zoneName, siteId, onUpdate }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  //Formik Validation For UserId and UserType
  const formik = useFormik({
    initialValues: {
      user_id: "",
    },
    validationSchema: Yup.object({
      user_id: Yup.string().required("User-ID is required"),
    }),
    onSubmit: async (values) => {
      const updatedValues = {
        ...values,
      };
      await handleSetUser(updatedValues);
      formik.resetForm();
    },
  });

  //Function For Set User To Site
  const handleSetUser = async (values) => {
    try {
      const accessToken = apiToken;
      const site_id = siteId;
      const zone_id = zoneId;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1//admin/site/${site_id}/zone/${zone_id}/add-user`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle("SUCCESS");
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(`Warning : ${response.data.status}`);
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        }
        setIsVisible(true);
      } else {
        console.error("Failed to add zone. Server error.");
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to add zone. Server error.");
        setSnackbarOpen(true);
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding zone:", error);
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Error adding zone.");
      setSnackbarOpen(true);
    }
    onUpdate(values);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      component={Paper}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.4s ease-in-out",
        transitionDelay: "0s",
      }}
    >
      {/* Alert Code For Response Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          <AlertTitle>
            <strong>{snackbarTitle.toUpperCase()}</strong>
          </AlertTitle>
          {snackbarMessage.toUpperCase()}
        </MuiAlert>
      </Snackbar>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            my: "10px",
            p: 2,
            m: 2,
            px: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              cursor: "default",
              letterSpacing: "1px",
              color: "#00695c",
              display: "flex",
              justifyContent: "flex-start",
              mt: "10px",
            }}
          >
            <PersonAdd
              sx={{ mt: "3px", mx: "2px", p: "1px", color: "#00695c" }}
            />
            <strong>{zoneName}</strong>
          </Typography>
          {/* Buttons  */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <ButtonGroup>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                size="small"
                sx={{
                  padding: "11px",
                  backgroundColor: "black",
                  mr: "3px",
                }}
                className={classes.addBtn}
                // disabled={!isUserFound}
              >
                <PersonAdd sx={{ fontSize: "21px", mx: "6px", mb: "2px" }} />
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Add To Zone
                </Typography>
              </Button>
              <Tooltip title="close" arrow placement="right">
                <Button
                  onClick={onClose}
                  variant="outlined"
                  color="error"
                  size="small"
                >
                  <Close
                    fontSize="small"
                    sx={{ marginBottom: "3px", mx: "3px", mt: "2px" }}
                  />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
        <Box sx={{ px: "20px", mx: "10px", height: "600px" }}>
          <TextField
            label="Enter User-ID"
            placeholder="Enter User ID"
            variant="standard"
            fullWidth
            name="user_id"
            margin="dense"
            value={formik.values.user_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.user_id && Boolean(formik.errors.user_id)}
            helperText={formik.touched.user_id ? formik.errors.user_id : ""}
          />
        </Box>
      </form>
    </Box>
  );
};

export default SetZoneUser;
