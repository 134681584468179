import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close,
  InfoSharp,
} from "@mui/icons-material";
import ZoneDetails from "./ZoneDetails";
import {
  DataGrid,
  GridColumnMenu,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  showBtn: {
    background: 'linear-gradient(90deg, #000000 0%, #130036 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #000000 0%, #969900 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
}));

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuFilterItem: null,
      }}
    />
  );
}


const ShowZones = ({ siteId, siteName, onClose }) => {
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [isVisible, setIsVisible] = useState(false);
  const [zones, setZones] = useState([]);
  const [id, setId] = useState("");
  const [detailsDialogOpen,  setDetailsDialogOpen] = useState(false);
  const classes = useStyles();

  //Handle Zone Details Dialog
  const handleDetailsDialogOpen = (id) => {
    setId(id);
    setDetailsDialogOpen(true);
  };

  const handleDetailsDialogClose = () => {
    setDetailsDialogOpen(false);
  };

  const handleZoneDelete = (deletedZoneId) => {
    setZones((prevZones) => prevZones.filter((zone) => zone.id !== deletedZoneId));
  };

  const handleAllZones = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/zones`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const responseData = response.data.data;
        if (response.data.status === "ok") {
          const allSites = responseData.zones.map((zone) => ({
            id: zone.zone_id,
            name: zone.zone_name,
          }));
          setZones(allSites);
        } else {
          console.error("No Zones found.");
        }
      } else {
        console.error("Failed to get all zones. Server error.");
      }
    } catch (error) {
      console.error("Error getting all zones:", error);
    }
  };

  useEffect(() => {
    setIsVisible(true);
    handleAllZones();
  }, []);

  return (
    <Box
      component={Paper}
      elevation={24}
      sx={{
        // overflow: "auto",
        maxWidth: "780px",
        minWidth: "200px",
        height: "714px",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
        transitionDelay: "0.1s",
        p: 2,
        mx: "auto",
        px: "auto",
        m: 0,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            p: 1,
            color: "#a56b00",
          }}
        >
           <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      // textAlign: "center",
                      p: 1,
                      pb: "5px",
                      pt:"0px",
                      cursor: "default",
                      textDecoration: "underline",
                      letterSpacing: "1px",
                      // color: "#a8720c",
                      color:"#00695c"
                    }}
                  >
                    <strong>{siteName}/{siteId}</strong>
                  </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
          <Tooltip title="Close" arrow placement="right">
            <IconButton onClick={onClose}>
              <Close sx={{color:"#00695c"}}/>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {/* Display Zones for selection */}
      {zones.length > 0 ? (
        <>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "center",
              mb: "10px",
              pb: "10px",
              pt: "5px",
            }}
          >
            ZONE/ZONES
          </Typography>
          <div style={{ height: 470, width: "100%" }}>
            <DataGrid
              rows={zones}
              columns={[
                { field: "id", headerName: "ID", width: 90 },
                {
                  field: "name",
                  headerName: "Name",
                  width: 160,
                  editable: true,
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  width: 150,
                  renderCell: (params) => (
                    <div>
                      <Button
                        variant="contained"
                        id="details-btn"
                        size="small"
                        onClick={() => handleDetailsDialogOpen(params.row.id)}
                        className={classes.showBtn}
                      >
                       <Typography sx={{fontWeight:"bold",fontSize:"14px",}}>Details</Typography>
                        <InfoSharp sx={{ fontSize: "15px", mx: "5px" }} />
                      </Button>
                    </div>
                  ),
                },
              ]}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 4 },
                },
              }}
              pageSizeOptions={[4, 10, 20, 50, 100]}
              sx={{ m: 1 }}
              slots={{
                toolbar: GridToolbarQuickFilter,
                columnMenu: CustomColumnMenu,
              }}
            />
          </div>
        </>
      ) : (
        <Box sx={{ mt: "30px",width:"310px" }}>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            No zones are added here
          </Typography>
        </Box>
      )}

      {/* Zone Details Block  */}
      <Dialog
        open={detailsDialogOpen}
        onClose={handleDetailsDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "5px",
              pt: "5px",
            }}
          >
            <Box sx={{ fontSize: "20px" }}>
              <Typography
                sx={{
                  // color: "#a56b00",
                  color:"#00695c",
                  fontWeight: "bold",
                  fontSize: "20px",
                  ml: "3px",
                }}
              >
                SITE : {siteId}/{siteName}
              </Typography>
            </Box>
            <Tooltip>
              <IconButton onClick={handleDetailsDialogClose}>
                <Close  sx={{color:"#00695c"}}/>
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* Render the SiteDetails component with the selected site ID */}
          {id && (
            <ZoneDetails
              id={id}
              siteId={siteId}
              onClose={handleDetailsDialogClose}
              onDelete={handleZoneDelete}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleDetailsDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShowZones;
