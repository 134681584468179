import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {
  AlertTitle,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, PersonAdd } from "@mui/icons-material";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import MuiAlert from "@mui/material/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  addBtn: {
    background: 'linear-gradient(90deg, #000000 0%, #130036 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #000000 0%, #004e00 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
}));

const SetSiteUser = ({ siteId, siteName, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  //Formik Validation For UserId and UserType
  const formik = useFormik({
    initialValues: {
      user_id: "",
      type: "",
    },
    validationSchema: Yup.object({
      user_id: Yup.string().required("User-ID is required"),
      type: Yup.string().required("User Type Required"),
    }),
    onSubmit: async (values) => {
      const updatedValues = {
        ...values,
      };
      await handleSetUser(updatedValues);
      formik.resetForm();
    },
  });

  //Function For Set User To Site
  const handleSetUser = async (values) => {
    try {
      const accessToken = apiToken;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/add-user`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle("SUCCESS");
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(`Warning : ${response.data.status}`);
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        }
        setIsVisible(true);
      } else {
        console.error("Failed to add zone. Server error.");
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to add zone. Server error.");
        setSnackbarOpen(true);
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding zone:", error);
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Error adding zone.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Paper
      elevation={24}
      style={{
        p: 2,
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
        transitionDelay: "0.1s",
        width: "350px",
      }}
    >
      {/* Alert Code For Response Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          <AlertTitle>
            <strong>{snackbarTitle.toUpperCase()}</strong>
          </AlertTitle>
          {snackbarMessage.toUpperCase()}
        </MuiAlert>
      </Snackbar>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            // textAlign: "center",
            p: 2,
            ml: "10px",
            mt: "2px",
            cursor: "default",
            textDecoration: "underline",
            letterSpacing: "1px",
            // color: "#a8720c",
            color: "#00695c",
          }}
        >
          <strong>
            {siteName}/{siteId}
          </strong>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
          <Tooltip title="Close" arrow placement="right">
            <IconButton onClick={onClose}>
              <Close sx={{ color: "#00695c" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
          mt: "20px",
        }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              my: "5px",
              py: "5px",
            }}
          >
            ADD USER TO SITE
          </Typography>
        </Box>
        <Box sx={{ px: "20px", mx: "20px", height: "575px", width: "300px" }}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label="Enter User-ID"
              name="user_id"
              placeholder="Enter User ID"
              variant="standard"
              fullWidth
              margin="dense"
              color="secondary"
              sx={{ my: "10px", py: "10px" }}
              value={formik.values.user_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.user_id && Boolean(formik.errors.user_id)}
              helperText={formik.touched.user_id ? formik.errors.user_id : ""}
            />
            <FormControl
              variant="standard"
              fullWidth
              sx={{ my: "10px", py: "10px", mb: "20px" }}
              error={formik.touched.type && Boolean(formik.errors.type)}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Select User Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="USER TYPE"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="site_admin">SITE-ADMIN</MenuItem>
                <MenuItem value="site_user">SITE-USER</MenuItem>
              </Select>
              {formik.touched.type && formik.errors.type && (
                <Typography variant="caption" color="error">
                  {formik.errors.type}
                </Typography>
              )}
            </FormControl>
            <Button
            className={classes.addBtn}
              variant="contained"
              color="primary"
              fullWidth
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
              sx={{
                my: "10px",
                py: "8px",
              }}
            >
              <PersonAdd sx={{ fontSize: "17px", mx: "5px", mb: "3px" }} />
              <Typography sx={{ fontWeight: "bold",fontSize:"14px" }}>Add To Site</Typography>
            </Button>
          </form>
        </Box>
      </Box>
    </Paper>
  );
};

export default SetSiteUser;
