import {
  AlertTitle,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import {
  Delete,
  DescriptionSharp,
  DonutSmallSharp,
  ExpandLess,
  ExpandMore,
  Folder,
  FolderCopy,
  Grid3x3,
  LocationCitySharp,
  People,
  PersonAdd,
  PhoneAndroidSharp,
  TuneSharp,
} from "@mui/icons-material";
import AddBox from "@mui/icons-material/AddBox";
import AddZoneDetails from "./AddZoneDetails";
import EditSiteDetails from "./EditSiteDetails";
import MuiAlert from "@mui/material/Alert";
import ShowZones from "./ShowZones";
import SetSiteUser from "./SetSiteUser";
import ShowSiteUsers from "./ShowSiteUsers";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  editBtn: {
    background: 'linear-gradient(90deg, #000000 0%, #130036 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #000000 0%, #006269 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
  addBtn: {
    background: 'linear-gradient(90deg, #000000 0%, #130036 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #000000 0%, #004e00 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
  showBtn: {
    background: 'linear-gradient(90deg, #000000 0%, #130036 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #000000 0%, #969900 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
  deleteBtn: {
    background: 'linear-gradient(90deg, #000000 0%, #130036 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #000000 0%, #860000 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
}));

const SiteDetails = ({ id }) => {
  const classes = useStyles();
  const [siteDetails, setSiteDetails] = useState(null);
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [currentPage, setCurrentPage] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  //Site Details Storing UseState
  const [siteId, setSiteId] = useState("");
  const [siteName, setSiteName] = useState("");

  // State to manage site details for editing
  const [editingSiteDetails, setEditingSiteDetails] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");

  //Showing Site Details
  const [open, setOpen] = React.useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Function to set editing site details and switch to edit mode
  const handleEditSite = () => {
    setEditingSiteDetails({
      id: siteDetails.id,
      name: siteDetails.name,
      short_name: siteDetails.short_name,
      description: siteDetails.description,
      contact_no: siteDetails.contact_no,
      address: siteDetails.address,
    });
    setCurrentPage("editSiteDetails");
  };

  // Function to update site details after editing
  const handleUpdateSiteDetails = (updatedDetails) => {
    setSiteDetails(updatedDetails);
    setCurrentPage(null);
    setSnackbarSeverity("success");
    setSnackbarTitle("Success");
    setSnackbarMessage("Site details updated successfully.");
    setSnackbarOpen(true);
  };

  const fetchSiteDetails = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/site/${id}/details`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setSiteDetails(response.data.data);
      } else {
        console.error("Failed to fetch site details. Server error.");
      }
    } catch (error) {
      console.error("Error searching for sites:", error);
    }
  };

  useEffect(() => {
    fetchSiteDetails();
  }, [id]);

  const handleAddZone = () => {
    setSiteId(siteDetails.id);
    setSiteName(siteDetails.name);
    setCurrentPage("addZoneDetails");
  };

  const handleAddUser = () => {
    setSiteId(siteDetails.id);
    setSiteName(siteDetails.name);
    setCurrentPage("addUser");
  };

  const handleShowZones = () => {
    setSiteId(siteDetails.id);
    setSiteName(siteDetails.name);
    setCurrentPage("showZones");
  };

  const handleShowUsers = () => {
    setSiteId(siteDetails.id);
    setSiteName(siteDetails.name);
    setCurrentPage("showSiteUsers");
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  //   Styles
  const btnStyle = { my: "5px", py: "10px" };
  const btnTextStyle = {
    fontWeight: "550",
    fontSize: "14px",
    letterSpacing: "0px",
    textAlign: "center",
  };

  return (
    <Box component="div" sx={{ mx: "auto", width: "100%", p: 2 }}>
      {/* Alert Code For Response Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          <AlertTitle>
            <strong>{snackbarTitle.toUpperCase()}</strong>
          </AlertTitle>
          {snackbarMessage.toUpperCase()}
        </MuiAlert>
      </Snackbar>

      {/* Dialog Box for Delete Site  */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Site"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to
            <strong style={{ color: "grey" }}>&nbsp;Delete</strong> this site?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              Cancel
            </Typography>
          </Button>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
              // handleDelete();
            }}
            color="error"
            autoFocus
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              Delete
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          mt: "30px",
          opacity: isVisible ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
          transitionDelay: "0.1s",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          width: "100%",
          height: "650px",
        }}
      >
        <Box sx={{ my: "10px", mb: "50px" }}>
          {siteDetails ? (
            <Paper
              elevation={24}
              // variant="outlined"
              sx={{ p: 2, m: 2, px: 3, width: "100%", pt: "10px", pb: "35px" }}
            >
              {/* Site Info  */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    // textAlign: "center",
                    p: 1,
                    pb: "5px",
                    cursor: "default",
                    textDecoration: "underline",
                    letterSpacing: "1px",
                    // color: "#a8720c",
                    color: "#00695c",
                    width: "100%",
                  }}
                >
                  <strong>
                    {siteDetails.name}/{siteDetails.id}
                  </strong>
                </Typography>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <Grid3x3 />
                    </ListItemIcon>
                    <ListItemText primary={`ID : ${siteDetails.id}`} />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemIcon>
                      <Folder />
                    </ListItemIcon>
                    <ListItemText primary={`Name : ${siteDetails.name}`} />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemIcon>
                      <FolderCopy />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Short Name : ${siteDetails.short_name}`}
                    />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemIcon>
                      <LocationCitySharp />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Address : ${siteDetails.address}`}
                      sx={{ overflow: "auto", maxWidth: "300px" }}
                    />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemIcon>
                      <PhoneAndroidSharp />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Contact No : ${siteDetails.contact_no}`}
                    />
                  </ListItemButton>
                  <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                      <DescriptionSharp />
                    </ListItemIcon>
                    <ListItemText primary="Description" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    sx={{ maxWidth: "300px" }}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 5 }}>
                        <ListItemText
                          primary={siteDetails.description}
                          sx={{ overflow: "auto" }}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  {/* <ListItemButton onClick={handleClick}>
                      <ListItemIcon>
                        <Person3 />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      sx={{ maxWidth: "300px" }}
                    >
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 5 }}>
                          <ListItemText
                            primary={siteDetails.description}
                            sx={{ overflow: "auto" }}
                          />
                        </ListItemButton>
                      </List>
                    </Collapse> */}
                </List>
              </Box>

              {/* Buttons  */}
              <Box sx={{ display: "grid", m: 1 }}>
                <Button
                  variant="contained"
                  sx={btnStyle}
                  onClick={handleEditSite}
                  id="edit-button"
                  className={classes.editBtn}
                >
                  <Typography sx={btnTextStyle}>Edit Site</Typography>
                  <TuneSharp sx={{ fontSize: "17px", mx: "5px", mb: "3px" }} />
                </Button>
                <Button
                  variant="contained"
                  sx={btnStyle}
                  onClick={handleAddUser}
                  id="add-button"
                  className={classes.addBtn}
                >
                  <Typography sx={btnTextStyle}>Add User</Typography>
                  <PersonAdd sx={{ fontSize: "18px", mx: "5px", mb: "2px" }} />
                </Button>
                <Button
                  variant="contained"
                  sx={btnStyle}
                  onClick={handleAddZone}
                  id="add-button"
                  className={classes.addBtn}
                >
                  <Typography sx={btnTextStyle}>Add Zone</Typography>
                  <AddBox sx={{ fontSize: "17px", mx: "5px", mb: "3px" }} />
                </Button>
                <Button
                  variant="contained"
                  sx={btnStyle}
                  onClick={handleShowUsers}
                  id="showZones-button"
                  className={classes.showBtn}
                >
                  <Typography sx={btnTextStyle}>Show Users</Typography>

                  <People sx={{ fontSize: "19px", mx: "5px", mb: "3px" }} />
                </Button>

                <Button
                  variant="contained"
                  sx={btnStyle}
                  onClick={handleShowZones}
                  id="showZones-button"
                  className={classes.showBtn}
                >
                  <Typography sx={btnTextStyle}>Show Zones</Typography>

                  <DonutSmallSharp
                    sx={{ fontSize: "17px", mx: "5px", mb: "3px" }}
                  />
                </Button>

                <Button
                  variant="contained"
                  sx={btnStyle}
                  onClick={handleDelete}
                  id="delete-button"
                  className={classes.deleteBtn}
                >
                  <Typography sx={btnTextStyle}>Delete Site</Typography>

                  <Delete sx={{ fontSize: "17px", mx: "5px", mb: "3px" }} />
                </Button>
              </Box>
            </Paper>
          ) : (
            <Box sx={{ mt: "30px" }}>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Loading site details...
              </Typography>
              <LinearProgress color="success" />
            </Box>
          )}
        </Box>

        {/* conditional rendering  */}
        <Box sx={{ py: "30px", px: "20px", mx: "10px" }}>
          {currentPage === "editSiteDetails" && (
            <EditSiteDetails
              onClose={() => setCurrentPage(null)}
              siteDetails={editingSiteDetails}
              siteId={siteDetails.id}
              onUpdate={handleUpdateSiteDetails}
            />
          )}
          {currentPage === "addUser" && (
            <SetSiteUser
              onClose={() => setCurrentPage(null)}
              siteDetails={editingSiteDetails}
              siteId={siteDetails.id}
              siteName={siteName}
              onUpdate={handleUpdateSiteDetails}
            />
          )}
          {currentPage === "addZoneDetails" && (
            <AddZoneDetails
              onClose={() => setCurrentPage(null)}
              siteId={siteId}
              siteName={siteName}
            />
          )}
          {currentPage === "showZones" && (
            <ShowZones
              onClose={() => setCurrentPage(null)}
              siteId={siteId}
              siteName={siteName}
            />
          )}
          {currentPage === "showSiteUsers" && (
            <ShowSiteUsers
              onClose={() => setCurrentPage(null)}
              siteId={siteId}
              siteName={siteName}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SiteDetails;
