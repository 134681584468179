import React from "react";
import { AppBar, Toolbar, Typography,Box } from "@mui/material";
import AdminPanelSettingsSharp from "@mui/icons-material/AdminPanelSettingsSharp";

const Header = () => {
  return (
    <div>
      <AppBar position="fixed" color="grey" sx={{ py: "6px" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            component="a"
            href="/login"
            sx={{
              mr: 2,
              //   display: { xs: "none", md: "flex" },
              color: "inherit",
              textDecoration: "none",
              display: "flex",
            }}
          >
            <AdminPanelSettingsSharp
              sx={{ fontSize: "28px", marginTop: "6px" }}
            />
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                //   display: { xs: "none", md: "flex" },
                fontFamily: "sans-serif",
                fontWeight: "bold",
                letterSpacing: ".1rem",
                color: "inherit",
                fontSize: "27px",
              }}
            >
              ADMIN
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
