import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import { CancelPresentationSharp, Close, InfoSharp, SettingsSuggest } from "@mui/icons-material";
import SiteDetails from "./SiteDetails";
import Slide from "@mui/material/Slide";
import {
  DataGrid,
  GridColumnMenu,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  getAllSitesBtn: {
    background: "linear-gradient(100deg, rgb(42, 0, 46) 0%, #1b0046 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #06003d 0%, #320036 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
  detailsBtn: {
    background: "linear-gradient(90deg, #000000 0%, #130036 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #000000 0%, #969900 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
  appBarBtn: {
    background: 'linear-gradient(90deg, #300046 0%, rgb(14, 0, 92) 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #41005f 0%, rgb(18, 0, 121) 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuFilterItem: null,
      }}
    />
  );
}

const SiteSearch = () => {
  const { state } = useAuth();
  const { apiToken, userId } = state;
  // const [searchCriteria, setSearchCriteria] = useState("");
  // const [matchingSites, setMatchingSites] = useState([]);
  // const [allSites, setAllSites] = useState([]);
  const [displayedSites, setDisplayedSites] = useState([]);
  const [id, setId] = useState("");
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleDetailsDialogOpen = (id) => {
    setId(id);
    setDetailsDialogOpen(true);
  };

  const handleDetailsDialogClose = () => {
    setDetailsDialogOpen(false);
  };

  // const handleSearchSites = async () => {
  //   try {
  //     const accessToken = apiToken;
  //     const response = await axios.get(
  //       `https://api.grvcle.in/bms/v1/admin/site-list-by-name/${searchCriteria}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `${userId}:${accessToken}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const responseData = response.data.data;
  //       if (responseData && responseData.sites) {
  //         const matchedSites = responseData.sites.map((site) => ({
  //           id: site.id,
  //           name: site.name,
  //         }));
  //         setMatchingSites(matchedSites);
  //         setDisplayedSites(matchedSites);
  //         // console.log("Matched Sites : ", matchedSites);
  //       } else {
  //         console.error("No matching sites found.");
  //       }
  //     } else {
  //       console.error("Failed to search for sites. Server error.");
  //     }
  //   } catch (error) {
  //     console.error("Error searching for sites:", error);
  //   }
  // };

  const handleAllSites = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/all-sites`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const responseData = response.data.data;
        if (responseData && responseData.sites) {
          const allSites = responseData.sites.map((site) => ({
            id: site.site_id,
            name: site.site_name,
          }));
          // setAllSites(allSites);
          setDisplayedSites(allSites);
          // console.log(allSites);
        } else {
          console.error("No sites found.");
        }
      } else {
        console.error("Failed to get all sites. Server error.");
      }
    } catch (error) {
      console.error("Error getting all sites:", error);
    }
  };

  const btnStyle = { padding: "5px", px: 2, margin: "2px", my: "16px" };

  return (
    <Box
      sx={{
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s ease-in-out",
        transitionDelay: "0.1s",
        height: "560px",
      }}
    >
      {/* Site Search Block  */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          fontSize={"21px"}
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            my: "5px",
            mx: "1px",
          }}
        >
          SEARCH & MANAGE SITE
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          margin: "5px",
          padding: "7px",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {/* Input for searching site by name */}
        {/* <TextField
          label="Search By Name"
          variant="outlined"
          fullWidth
          value={searchCriteria}
          sx={{ marginBottom: "10px", width: "350px" }}
          onChange={(e) => setSearchCriteria(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSearchSites}
                >
                  <SearchSharp />
                </Button>
              </InputAdornment>
            ),
          }}
        /> */}
        <Button
          className={classes.getAllSitesBtn}
          variant="contained"
          // sx={{ mx: "10px", p: 2, py: "10px", height: "55px" }}
          sx={{ mx: "5px", p: 2, py: "10px" }}
          size="small"
          onClick={handleAllSites}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
            GET ALL SITES
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          overflow: "auto",
          height: "500px",
          opacity: isVisible ? 1 : 0,
          transition: "opacity 1s ease-in-out",
          transitionDelay: "0.1s",
          // display:"flex",justifyContent:"center"
        }}
      >
        {/* Display sites for selection */}
        {displayedSites.length > 0 && (
          <div>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                my: "5px",
                py: "1px",
              }}
            >
              SITE/SITES
            </Typography>
            <div style={{ height: 390, width: "100%", textAlign: "center" }}>
              <DataGrid
                rows={displayedSites}
                columns={[
                  { field: "id", headerName: "ID", width: 100 },
                  {
                    field: "name",
                    headerName: "Name",
                    width: 200,
                    editable: true,
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    width: 250,
                    renderCell: (params) => (
                      <div>
                        <Button
                          className={classes.detailsBtn}
                          variant="contained"
                          id="details-btn"
                          size="small"
                          sx={btnStyle}
                          onClick={() => handleDetailsDialogOpen(params.row.id)}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              letterSpacing: "0px",
                            }}
                          >
                            Details
                          </Typography>
                          <InfoSharp
                            sx={{ fontSize: "14px", mx: "5px", mb: "2px" }}
                          />
                        </Button>
                      </div>
                    ),
                  },
                ]}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 4 },
                  },
                }}
                pageSizeOptions={[4, 10, 20, 50, 100]}
                sx={{ m: 1 }}
                slots={{
                  toolbar: GridToolbarQuickFilter,
                  columnMenu: CustomColumnMenu,
                }}
              />
            </div>
          </div>
        )}
      </Box>

      {/* Site Details Block  */}
      <Dialog
        open={detailsDialogOpen}
        onClose={handleDetailsDialogClose}
        fullScreen
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBarBtn} sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDetailsDialogClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Box sx={{ ml: 2, flex: 1 }}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", letterSpacing: "2px" }}
                >
                  SITE MANAGEMENT
                </Typography>
                <SettingsSuggest sx={{ mt: "1px", mx: "3px" }} />
              </Box>
            </Box>
            <Button
              autoFocus
              color="inherit"
              onClick={handleDetailsDialogClose}
              variant="text"
            >
              <CancelPresentationSharp sx={{fontSize:"16px", mx:"3px",mb:"1px"}}/>
              <Typography sx={{ fontWeight: "bold",fontSize:"15px" }}>close</Typography>
            </Button>
          </Toolbar>
        </AppBar>
        {/* <DialogTitle>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            SITE DETAILS &gt;
          </Typography>
        </DialogTitle> */}
        <DialogContent>
          {/* Render the SiteDetails component with the selected site ID */}
          {id && <SiteDetails id={id} onClose={handleDetailsDialogClose} />}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SiteSearch;
