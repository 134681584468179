import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddNewSite from "./AddNewSite";
import "./SiteManagement.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import SiteSearch from "./SiteSearch";
import { HomeOutlined } from "@mui/icons-material";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  indexBtn: {
    background: 'linear-gradient(100deg, rgb(42, 0, 46) 0%, #100029 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #06003d 0%, #320036 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
}));

const SiteManagement = () => {
  const [currentPage, setCurrentPage] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const classes = useStyles();

  const handleHomeClick = () => {
    try {
      setIsRefreshing(true);
      setCurrentPage(null);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      setCurrentPage(null);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <Box component="div">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
        >
          <Tooltip title="Go Home" arrow placement="bottom">
            <IconButton color="info" onClick={handleHomeClick}>
              <HomeOutlined
                style={{
                  cursor: "pointer",
                  transition: "transform 0.1s ease",
                  transform: isRefreshing ? "rotate(360deg)" : "rotate(0deg)",
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontSize: "25px",
            fontWeight: "bold",
            py: "5px",
            marginBottom: "15px",
          }}
        >
          SITE MANAGEMENT
        </Typography>
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
        >
          {isRefreshing ? (
            <IconButton color="error" onClick={() => setIsRefreshing(false)}>
              <CloseIcon style={{ cursor: "pointer" }} />
            </IconButton>
          ) : (
            <Tooltip title="Refresh" arrow placement="bottom">
              <IconButton color="info" onClick={handleRefresh}>
                <RefreshIcon
                  style={{
                    cursor: "pointer",
                    transition: "transform 0.1s ease",
                    transform: isRefreshing ? "rotate(360deg)" : "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Grid container spacing={1} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Grid item xs={12} md={12}>
          <Box
            component={Paper}
            elevation={1}
            sx={{
              py: "10px",
              my: "7px",
              px: "20px",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
            className={classes.indexBtn}
              variant="contained"
              onClick={() => setCurrentPage("addNewSite")}
              sx={{
                my: "7px",
                py: "15px",
                mx: "10px",
                fontSize: "16px",
                width: "250px",
              }}
            >
              <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                Add New Site
              </Typography>
            </Button>
            <Button
              className={classes.indexBtn}
              variant="contained"
              onClick={() => setCurrentPage("searchSite")}
              sx={{
                my: "7px",
                py: "15px",
                mx: "10px",
                fontSize: "16px",
                width: "250px",
              }}
            >
              <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                Search Site
              </Typography>
            </Button>
          </Box>
          <Box
            component={Paper}
            sx={{ py: "30px", px: "20px", minHeight: "500px" }}
          >
            {currentPage === null && (
              <AddNewSite onClose={() => setCurrentPage(null)} />
            )}
            {currentPage === "addNewSite" && (
              <AddNewSite onClose={() => setCurrentPage(null)} />
            )}
            {currentPage === "searchSite" && (
              <SiteSearch onClose={() => setCurrentPage(null)} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SiteManagement;
