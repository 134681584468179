import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Avatar,
  Paper,
  Snackbar,
  AlertTitle,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import FolderIcon from "@mui/icons-material/Folder";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import AddHomeIcon from "@mui/icons-material/AddHome";
import { grey } from "@mui/material/colors";
import AddBoxIcon from "@mui/icons-material/AddBox";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  addSiteBtn: {
    background: "linear-gradient(90deg, #14003a 0%, #450047 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #34003b 0%, #1e0057 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
  
}));

const AddNewSite = () => {
  const { state } = useAuth();
  const { apiToken, userId } = state;
  // const [siteId, setSiteId] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      short_name: "",
      address: "",
      contact_no: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      short_name: Yup.string().required("Short Name is required"),
      address: Yup.string().required("Address is required"),
      contact_no: Yup.number().required("Contact No is required"),
    }),
    onSubmit: async (values) => {
      await handleAddSite(values);
      formik.resetForm();
    },
  });

  const handleAddSite = async (values) => {
    try {
      const accessToken = apiToken;
      const response = await axios.post(
        "https://api.grvcle.in/bms/v1/admin/site/add",
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle(
            `Success, Site Id : ${response.data.data["site-id"]}`
          );
          setSnackbarMessage(`${response.data.message}`);
          setSnackbarOpen(true);
          console.log(
            "Site added successfully. Site ID:",
            response.data.data["site-id"]
          );
          // setSiteId(response.data.data["site-id"]);
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          console.log(
            "Site data not added, there is a fault. Site ID:",
            response.data.data["site-id"]
          );
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          console.log(
            "Site data not added, Site ID:",
            response.data.data["site-id"]
          );
        }
        setIsVisible(true);
      } else {
        console.error("Failed to add site. Server error.");
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to add zone. Server error.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error adding site:", error);
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Error adding zone.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //Styles
  const fieldStyle = { marginTop: "10px", marginBottom: "7px" };
  const IconStyle = {
    fontSize: "23px",
    color: "black",
  };

  return (
    <Box
      sx={{
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s ease-in-out",
        transitionDelay: "0.1s",
      }}
    >
      {/* Alert Code For Response Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          <AlertTitle>
            <strong>{snackbarTitle.toUpperCase()}</strong>
          </AlertTitle>
          {snackbarMessage.toUpperCase()}
        </MuiAlert>
      </Snackbar>
      <Box
        component={Paper}
        elevation={24}
        sx={{
          padding: "15px",
          marginBottom: "15px",
          maxWidth: "300px",
          px: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          mx: "auto",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", my: "8px" }}>
          <Avatar
            variant="square"
            sx={{
              bgcolor: grey[900],
              width: "18px",
              height: "18px",
              marginTop: "16px",
              mx: "5px",
            }}
          >
            <AddBoxIcon fontSize="small" sx={{ p: "3px", m: "2px" }} />
          </Avatar>
          <Typography
            fontSize={"21px"}
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              my: "10px",
              mx: "1px",
            }}
          >
            ADD NEW SITE
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Site Name"
            variant="standard"
            fullWidth
            id="name"
            name="name"
            sx={fieldStyle}
            margin="dense"
            type="text"
            placeholder="Enter Site Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FolderIcon sx={IconStyle} />
                </InputAdornment>
              ),
            }}
            color="secondary"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            label="Short Name"
            variant="standard"
            fullWidth
            id="short_name"
            name="short_name"
            margin="dense"
            type="text"
            placeholder="Enter Short Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FolderCopyIcon sx={IconStyle} />
                </InputAdornment>
              ),
            }}
            color="secondary"
            value={formik.values.short_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.short_name && Boolean(formik.errors.short_name)
            }
            helperText={formik.touched.short_name && formik.errors.short_name}
            sx={fieldStyle}
          />
          <TextField
            label="Address"
            variant="standard"
            fullWidth
            id="address"
            name="address"
            margin="dense"
            type="text"
            placeholder="Enter Address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceIcon sx={IconStyle} />
                </InputAdornment>
              ),
            }}
            color="secondary"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            sx={fieldStyle}
          />
          <TextField
            label="Contact No"
            variant="standard"
            fullWidth
            id="contact_no"
            name="contact_no"
            margin="dense"
            type="text"
            placeholder="Enter Contact Number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon sx={IconStyle} />
                </InputAdornment>
              ),
            }}
            color="secondary"
            value={formik.values.contact_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.contact_no && Boolean(formik.errors.contact_no)
            }
            helperText={formik.touched.contact_no && formik.errors.contact_no}
            sx={fieldStyle}
          />
          <TextField
            label="Description"
            variant="standard"
            fullWidth
            id="description"
            name="description"
            margin="dense"
            multiline
            maxRows={4}
            type="text"
            placeholder="Add Description"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DescriptionIcon sx={IconStyle} />
                </InputAdornment>
              ),
            }}
            color="secondary"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            sx={fieldStyle}
          />
          <Box sx={{ marginTop: "15px", marginBottom: "20px" }}>
            <Button
            className={classes.addSiteBtn}
              type="submit"
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ padding: "10px", backgroundColor: "black" }}
            >
              <AddHomeIcon
                fontSize="small"
                sx={{ marginBottom: "3px", mx: "1px" }}
              />
              <Typography sx={{ fontSize: "17px", mx: "1px" }}>Add</Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AddNewSite;
