
// This authentication will work fine, but if the user refresh the browser the user and authentication details will be gone
// import React, { createContext, useReducer, useContext } from 'react';

// // Define initial state
// const initialState = {
//   apiToken: localStorage.getItem('apiToken') || null,
//   userId: null,
//   errorMessage: '',
// };

// // Define actions 
// const SET_API_TOKEN = 'SET_API_TOKEN';
// const SET_USER_ID = 'SET_USER_ID';
// const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

// // Define reducer function
// const authReducer = (state, action) => {
//   switch (action.type) {
//     case SET_API_TOKEN:
//       return { ...state, apiToken: action.payload };
//     case SET_USER_ID:
//       return { ...state, userId: action.payload };
//     case SET_ERROR_MESSAGE:
//       return { ...state, errorMessage: action.payload };
//     default:
//       return state;
//   }
// };

// // Create context
// const AuthContext = createContext();

// // Create context provider
// const AuthProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(authReducer, initialState);

//   const setApiToken = (apiToken) => {
//     dispatch({ type: SET_API_TOKEN, payload: apiToken });
//   };

//   const setUserId = (userId) => {
//     dispatch({ type: SET_USER_ID, payload: userId });
//   };

//   const setErrorMessage = (errorMessage) => {
//     dispatch({ type: SET_ERROR_MESSAGE, payload: errorMessage });
//   };

//   return (
//     <AuthContext.Provider
//       value={{ state, setApiToken, setUserId, setErrorMessage }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

// // Create custom hook to use the context
// const useAuth = () => useContext(AuthContext);

// export { AuthProvider, useAuth };

// This authentication will work fine, if the user refresh the browser the user and authentication details will be stored in local storage
import React, { createContext, useReducer, useContext, useEffect } from 'react';

// Define initial state
const initialState = {
  apiToken: localStorage.getItem('apiToken') || null,
  userId: localStorage.getItem('userId') || null,
  errorMessage: '',
};

// Define actions 
const SET_API_TOKEN = 'SET_API_TOKEN';
const SET_USER_ID = 'SET_USER_ID';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

// Define reducer function
const authReducer = (state, action) => {
  switch (action.type) {
    case SET_API_TOKEN:
      localStorage.setItem('apiToken', action.payload);
      return { ...state, apiToken: action.payload };
    case SET_USER_ID:
      localStorage.setItem('userId', action.payload);
      return { ...state, userId: action.payload };
    case SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

// Create context
const AuthContext = createContext();

// Create context provider
const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const setApiToken = (apiToken) => {
    dispatch({ type: SET_API_TOKEN, payload: apiToken });
  };

  const setUserId = (userId) => {
    dispatch({ type: SET_USER_ID, payload: userId });
  };

  const setErrorMessage = (errorMessage) => {
    dispatch({ type: SET_ERROR_MESSAGE, payload: errorMessage });
  };

  useEffect(() => {
    const logoutAfterTimeout = setTimeout(() => {
      setApiToken(null);
      setUserId(null);
      // Additional cleanup logic if needed
    }, 24 * 60 * 60 * 1000); // 24 hours  

    return () => clearTimeout(logoutAfterTimeout);
  }, [state.apiToken, setApiToken, setUserId]);

  return (
    <AuthContext.Provider
      value={{ state, setApiToken, setUserId, setErrorMessage }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Create custom hook to use the context
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
