import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  Paper,
  Snackbar,
  AlertTitle,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddHomeIcon from "@mui/icons-material/AddHome";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import { grey } from "@mui/material/colors";
import MuiAlert from "@mui/material/Alert";
import { Close, LabelImportantSharp } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  addZoneBtn: {
    background: "linear-gradient(90deg, #14003a 0%, #450047 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #34003b 0%, #1e0057 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
}));

const AddZoneDetails = ({ siteId, siteName, onClose }) => {
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [zoneIDs, setZoneIDs] = useState([]);
  const [defaultZoneId, setDefaultZoneId] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make sure to set setIsVisible before making the API call
        setIsVisible(true);

        const accessToken = apiToken;
        const response = await axios.get(
          `https://api.grvcle.in/bms/v1/admin/site/${siteId}/zones`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${userId}:${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const responseData = response.data.data;
          if (response.data.status === "ok") {
            const allSites = responseData.zones.map((zone) => zone.zone_id);
            const sortedZoneIDs = allSites.sort((a, b) => a - b);
            setZoneIDs(sortedZoneIDs);

            const lastValue =
              sortedZoneIDs.length > 0
                ? sortedZoneIDs[sortedZoneIDs.length - 1]
                : 0;
            const nextValue = isNaN(lastValue) ? 1 : lastValue + 1;
            setDefaultZoneId(nextValue);
          } else {
            console.error("No Zones found.");
          }
        } else {
          console.error("Failed to get all zones. Server error.");
        }
      } catch (error) {
        console.error("Error getting all zones:", error);
      }
    };

    fetchData();
  }, [apiToken, userId, siteId]);

  useEffect(() => {
    // Convert defaultZoneId to a string before setting it
    formikAddZone.setFieldValue("zone_id", String(defaultZoneId));
  }, [defaultZoneId]);

  const validateRange = (min, max) => ({
    name: "range",
    exclusive: false, // Ensures that the value can be equal to the min or max
    message: `Value should be between ${min} and ${max}`,
    test: (value) => {
      return value >= min && value <= max;
    },
  });

  const isIPAddressValid = (ip) => {
    const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return ipRegex.test(ip);
  };

  const formikAddZone = useFormik({
    initialValues: {
      zone_id: defaultZoneId,
      name: "",
      description: "",
      iot_device_id: "",
      data_pr_time: "",
      iot_device_secure_token: "",
      devices_server_ip: "",
      devices_server_port: "",
      battery_type: "",
      nominal_cell_vol: "",
      cell_ov_thr: "",
      cell_uv_thr: "",
      battery_uv_thr: "",
      battery_ov_thr: "",
      cell_count: "",
      temperature_count: "",
      temp_high_thr: "",
      temp_low_thr: "",
    },
    validationSchema: Yup.object({
      zone_id: Yup.mixed()
        .required("ID is required")
        .test("ID available", "ID not available", (value) => {
          // Check if the entered value exists in the zoneIDs array
          const inputValue = String(value); // Convert input value to string
          return !zoneIDs.map(String).includes(inputValue);
        })
        .test(validateRange(1, 32768)),
      name: Yup.string().required("Name is required"),
      description: Yup.string(),
      iot_device_id: Yup.string()
        .required("IoT Device ID is required")
        .test(validateRange(1, 32768)),
      data_pr_time: Yup.string()
        .required("Data PR Time is required")
        .test(validateRange(1, 32768)),
      iot_device_secure_token: Yup.string().required(
        "IoT Device Secure Token is required"
      ),
      devices_server_ip: Yup.string()
        .required("Devices Server IP is required")
        .test("valid-ip", "Invalid IP address", (value) => {
          return isIPAddressValid(value); // Replace isIPAddressValid with your validation logic
        }),
      devices_server_port: Yup.string()
        .required("Devices Server Port is required")
        .test(validateRange(1, 32768)),
      battery_type: Yup.string().required("Battery Type is required"),
      nominal_cell_vol: Yup.string().required("Nominal Cell Vol is required"),
      cell_ov_thr: Yup.string().required("Cell OV Threshold is required"),
      cell_uv_thr: Yup.string().required("Cell UV Threshold is required"),
      battery_uv_thr: Yup.string().required("Battery UV Threshold is required"),
      battery_ov_thr: Yup.string().required("Battery OV Threshold is required"),
      cell_count: Yup.string()
        .required("Cell Count is required")
        .test(validateRange(1, 32768)),
      temperature_count: Yup.string()
        .required("Temperature Count is required")
        .test(validateRange(1, 32768)),
      temp_high_thr: Yup.string().required(
        "Temperature High Threshold is required"
      ),
      temp_low_thr: Yup.string().required(
        "Temperature Low Threshold is required"
      ),
    }),
    onSubmit: async (values) => {
      const updatedValues = {
        ...values,
      };

      await handleAddZone(updatedValues);
      formikAddZone.resetForm();
    },
  });

  const handleAddZone = async (values) => {
    // Convert specific fields to integers
    const integerFields = [
      "zone_id",
      "iot_device_id",
      "data_pr_time",
      "devices_server_port",
      "cell_count",
      "temperature_count",
    ];

    integerFields.forEach((field) => {
      values[field] = parseInt(values[field], 10);
      if (Number.isInteger(values[field])) {
      }
    });

    // Convert specific fields to floats
    const floatFields = [
      "nominal_cell_vol",
      "cell_ov_thr",
      "cell_uv_thr",
      "battery_uv_thr",
      "battery_ov_thr",
      "temp_high_thr",
      "temp_low_thr",
    ];

    floatFields.forEach((field) => {
      values[field] = parseFloat(values[field]);
      if (Number.isInteger(values[field])) {
      }
    });
    try {
      const accessToken = apiToken;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/zone/add`,

        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle("SUCCESS");
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(`Warning : ${response.data.status}`);
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          console.log(response.data.message);
          setSnackbarOpen(true);
        }
        setIsVisible(true);
      } else {
        console.error("Failed to add zone. Server error.");
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to add zone. Server error.");
        setSnackbarOpen(true);
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding zone:", error);
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Error adding zone.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      component={Paper}
      elevation={24}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
        transitionDelay: "0.1s",
      }}
    >
      {/* Alert Code For Response Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          <AlertTitle>
            <strong>{snackbarTitle.toUpperCase()}</strong>
          </AlertTitle>
          {snackbarMessage.toUpperCase()}
        </MuiAlert>
      </Snackbar>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            // textAlign: "center",
            p: 2,
            ml: "10px",
            mt: "2px",
            cursor: "default",
            textDecoration: "underline",
            letterSpacing: "1px",
            // color: "#a8720c",
            color: "#00695c",
          }}
        >
          <strong>
            {siteName}/{siteId}
          </strong>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
          <Tooltip title="Close" arrow placement="right">
            <IconButton onClick={onClose}>
              <Close sx={{ color: "#00695c" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: "5px",
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: grey[900],
            width: "18px",
            height: "18px",
            marginTop: "16px",
            mx: "2px",
          }}
        >
          <AddBoxIcon fontSize="small" sx={{ p: "3px", m: "2px" }} />
        </Avatar>
        <Typography
          fontSize={"21px"}
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            my: "10px",
            mx: "1px",
          }}
        >
          ADD ZONE DETAILS
        </Typography>
      </Box>
      <form onSubmit={formikAddZone.handleSubmit}>
        <Box
          sx={{
            width: "310px",
            mx: "20px",
            mb: "10px",
            px: "25px",
            py: "15px",
            pb: "30px",
            overflow: "auto",
            height: "485px",
          }}
        >
          <TextField
            label="Zone ID"
            variant="standard"
            fullWidth
            id="zone_id"
            name="zone_id"
            margin="dense"
            type="text"
            placeholder="Zone ID"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LabelImportantSharp sx={{ color: "black" }} />
                </InputAdornment>
              ),
            }}
            value={formikAddZone.values.zone_id}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.zone_id &&
              Boolean(formikAddZone.errors.zone_id)
            }
            helperText={
              formikAddZone.touched.zone_id && formikAddZone.errors.zone_id
            }
            sx={{
              "& .MuiFormHelperText-root": {
                color:
                  formikAddZone.touched.zone_id &&
                  (formikAddZone.errors.zone_id ||
                    (formikAddZone.values.zone_id !== "" &&
                      !zoneIDs.includes(String(formikAddZone.values.zone_id))))
                    ? "red"
                    : "green",
              },
            }}
          />

          <TextField
            label="Zone Name"
            variant="standard"
            fullWidth
            id="name"
            name="name"
            margin="dense"
            type="text"
            placeholder="Zone Name"
            value={formikAddZone.values.name}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.name && Boolean(formikAddZone.errors.name)
            }
            helperText={formikAddZone.touched.name && formikAddZone.errors.name}
          />
          <TextField
            label="Description"
            variant="standard"
            fullWidth
            id="description"
            name="description"
            margin="dense"
            type="text"
            multiline
            maxRows={4}
            placeholder="Description"
            value={formikAddZone.values.description}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.description &&
              Boolean(formikAddZone.errors.description)
            }
            helperText={
              formikAddZone.touched.description &&
              formikAddZone.errors.description
            }
          />
          <TextField
            label="iot-device-id"
            variant="standard"
            fullWidth
            id="iot_device_id"
            name="iot_device_id"
            margin="dense"
            type="text"
            placeholder="iot-device-id : [int]"
            value={formikAddZone.values.iot_device_id}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.iot_device_id &&
              Boolean(formikAddZone.errors.iot_device_id)
            }
            helperText={
              formikAddZone.touched.iot_device_id &&
              formikAddZone.errors.iot_device_id
            }
          />
          <TextField
            label="data-pr-time"
            variant="standard"
            fullWidth
            id="data_pr_time"
            name="data_pr_time"
            margin="dense"
            type="text"
            placeholder="data-pr-time : [int]"
            value={formikAddZone.values.data_pr_time}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.data_pr_time &&
              Boolean(formikAddZone.errors.data_pr_time)
            }
            helperText={
              formikAddZone.touched.data_pr_time &&
              formikAddZone.errors.data_pr_time
            }
          />
          <TextField
            label="iot-device-secure-token"
            variant="standard"
            fullWidth
            id="iot_device_secure_token"
            name="iot_device_secure_token"
            margin="dense"
            type="text"
            multiline
            maxRows={3}
            placeholder="secure-token : [string]"
            value={formikAddZone.values.iot_device_secure_token}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.iot_device_secure_token &&
              Boolean(formikAddZone.errors.iot_device_secure_token)
            }
            helperText={
              formikAddZone.touched.iot_device_secure_token &&
              formikAddZone.errors.iot_device_secure_token
            }
          />
          <TextField
            label="devices-server-ip"
            variant="standard"
            fullWidth
            id="devices_server_ip"
            name="devices_server_ip"
            margin="dense"
            type="text"
            placeholder="IPv4 address : Ex-[192.168.1.1]"
            value={formikAddZone.values.devices_server_ip}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.devices_server_ip &&
              Boolean(formikAddZone.errors.devices_server_ip)
            }
            helperText={
              formikAddZone.touched.devices_server_ip &&
              formikAddZone.errors.devices_server_ip
            }
          />
          <TextField
            label="devices-server-port"
            variant="standard"
            fullWidth
            id="devices_server_port"
            name="devices_server_port"
            margin="dense"
            type="text"
            placeholder="server-port : [int]"
            value={formikAddZone.values.devices_server_port}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.devices_server_port &&
              Boolean(formikAddZone.errors.devices_server_port)
            }
            helperText={
              formikAddZone.touched.devices_server_port &&
              formikAddZone.errors.devices_server_port
            }
          />
          <TextField
            label="battery-type"
            variant="standard"
            fullWidth
            id="battery_type"
            name="battery_type"
            margin="dense"
            type="text"
            placeholder="battery-type"
            value={formikAddZone.values.battery_type}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.battery_type &&
              Boolean(formikAddZone.errors.battery_type)
            }
            helperText={
              formikAddZone.touched.battery_type &&
              formikAddZone.errors.battery_type
            }
          />
          <TextField
            label="nominal-cell-vol"
            variant="standard"
            fullWidth
            id="nominal_cell_vol"
            name="nominal_cell_vol"
            margin="dense"
            type="text"
            placeholder="nominal-cell-vol : [float]"
            value={formikAddZone.values.nominal_cell_vol}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.nominal_cell_vol &&
              Boolean(formikAddZone.errors.nominal_cell_vol)
            }
            helperText={
              formikAddZone.touched.nominal_cell_vol &&
              formikAddZone.errors.nominal_cell_vol
            }
          />
          <TextField
            label="cell-ov-threshold"
            variant="standard"
            fullWidth
            id="cell_ov_thr"
            name="cell_ov_thr"
            margin="dense"
            type="text"
            placeholder="cell-ov-thr : [float]"
            value={formikAddZone.values.cell_ov_thr}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.cell_ov_thr &&
              Boolean(formikAddZone.errors.cell_ov_thr)
            }
            helperText={
              formikAddZone.touched.cell_ov_thr &&
              formikAddZone.errors.cell_ov_thr
            }
          />
          <TextField
            label="cell-uv-threshold"
            variant="standard"
            fullWidth
            id="cell_uv_thr"
            name="cell_uv_thr"
            margin="dense"
            type="text"
            placeholder="cell-uv-thr : [float]"
            value={formikAddZone.values.cell_uv_thr}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.cell_uv_thr &&
              Boolean(formikAddZone.errors.cell_uv_thr)
            }
            helperText={
              formikAddZone.touched.cell_uv_thr &&
              formikAddZone.errors.cell_uv_thr
            }
          />
          <TextField
            label="battery-uv-threshold"
            variant="standard"
            fullWidth
            id="battery_uv_thr"
            name="battery_uv_thr"
            margin="dense"
            type="text"
            placeholder="battery-uv-thr : [float]"
            value={formikAddZone.values.battery_uv_thr}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.battery_uv_thr &&
              Boolean(formikAddZone.errors.battery_uv_thr)
            }
            helperText={
              formikAddZone.touched.battery_uv_thr &&
              formikAddZone.errors.battery_uv_thr
            }
          />
          <TextField
            label="battery-ov-threshold"
            variant="standard"
            fullWidth
            id="battery_ov_thr"
            name="battery_ov_thr"
            margin="dense"
            type="text"
            placeholder="battery-ov-thr : [float]"
            value={formikAddZone.values.battery_ov_thr}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.battery_ov_thr &&
              Boolean(formikAddZone.errors.battery_ov_thr)
            }
            helperText={
              formikAddZone.touched.battery_ov_thr &&
              formikAddZone.errors.battery_ov_thr
            }
          />
          <TextField
            label="cell-count"
            variant="standard"
            fullWidth
            id="cell_count"
            name="cell_count"
            margin="dense"
            type="text"
            placeholder="cell-count : [int}"
            value={formikAddZone.values.cell_count}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.cell_count &&
              Boolean(formikAddZone.errors.cell_count)
            }
            helperText={
              formikAddZone.touched.cell_count &&
              formikAddZone.errors.cell_count
            }
          />
          <TextField
            label="temperature-count "
            variant="standard"
            fullWidth
            id="temperature_count"
            name="temperature_count"
            margin="dense"
            type="text"
            placeholder="temp-count : [int]"
            value={formikAddZone.values.temperature_count}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.temperature_count &&
              Boolean(formikAddZone.errors.temperature_count)
            }
            helperText={
              formikAddZone.touched.temperature_count &&
              formikAddZone.errors.temperature_count
            }
          />
          <TextField
            label="temp-high-threshold"
            variant="standard"
            fullWidth
            id="temp_high_thr"
            name="temp_high_thr"
            margin="dense"
            type="text"
            placeholder="temp-high-thr : [float]"
            value={formikAddZone.values.temp_high_thr}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.temp_high_thr &&
              Boolean(formikAddZone.errors.temp_high_thr)
            }
            helperText={
              formikAddZone.touched.temp_high_thr &&
              formikAddZone.errors.temp_high_thr
            }
          />
          <TextField
            label="temp-low-threshold"
            variant="standard"
            fullWidth
            id="temp_low_thr"
            name="temp_low_thr"
            margin="dense"
            type="text"
            placeholder="temp-low-thr : [float]"
            value={formikAddZone.values.temp_low_thr}
            onChange={formikAddZone.handleChange}
            onBlur={formikAddZone.handleBlur}
            error={
              formikAddZone.touched.temp_low_thr &&
              Boolean(formikAddZone.errors.temp_low_thr)
            }
            helperText={
              formikAddZone.touched.temp_low_thr &&
              formikAddZone.errors.temp_low_thr
            }
          />
        </Box>
        <Box
          sx={{ my: "20px", mx: "15px", px: "20px", pb: "20px", mb: "20px" }}
        >
          <Button
            className={classes.addZoneBtn}
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ padding: "10px", backgroundColor: "black" }}
          >
            <AddHomeIcon
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "3px",
                mx: "1px",
              }}
            />
            <Typography sx={{ fontWeight: "550", fontSize: "15px", mx: "2px" }}>
              Add Zone Details
            </Typography>
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddZoneDetails;
