import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
import LoginPage from "../bms-login/LoginPage";
import NotFound from "./NotFound";
import ManagementIndex from "../bms-management/ManagementIndex";

const IndexPage = () => {
  return (
    <Router>
      <Container component="div" sx={{ height: "700px" }}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/management-index" element={<ManagementIndex />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default IndexPage;
