import {
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import {
  Delete,
  Description,
  Edit,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
  PersonAddAlt1Sharp,
} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import EditZoneDetails from "./EditZoneDetails";
import SetZoneUser from "./SetZoneUser";
import {
  DataGrid,
  GridColumnMenu,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  editBtn: {
    background: "linear-gradient(90deg, #000000 0%, #130036 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #000000 0%, #006269 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
  addBtn: {
    background: "linear-gradient(90deg, #000000 0%, #130036 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #000000 0%, #004e00 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
  deleteBtn: {
    background: "linear-gradient(90deg, #000000 0%, #130036 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #000000 0%, #860000 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
}));

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuFilterItem: null,
      }}
    />
  );
}

const ZoneDetails = ({ id, siteId, onDelete, onClose }) => {
  const [zoneDetails, setZoneDetails] = useState(null);
  const [isZoneDeleted, setIsZoneDeleted] = useState(false);
  // const [showContent, setShowContent] = useState(true);
  const [editingZoneDetails, setEditingZoneDetails] = useState(null);
  const { state } = useAuth();
  const { apiToken, userId } = state;
  // const [isVisible, setIsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const classes = useStyles();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  // const [zoneId, setZoneId] = useState("");
  // const [zoneName, setZoneName] = useState("");

  //Showing Site Details
  const [open, setOpen] = React.useState(false);
  const [usersOpen, setUsersOpen] = React.useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [users, setUsers] = useState([]);
  const [idToRemove, setIdToRemove] = useState(null);

  const handleRemoveUser = (userId) => {
    setIdToRemove(userId);
  };

  const removeUser = async () => {
    try {
      const accessToken = apiToken;
      const user_id = idToRemove;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/zone/${id}/delete-user`,
        { user_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle("SUCCESS");
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          handleAllZoneUsers();
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(`Warning `);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        }
        // setTimeout(() => {
        //   onClose();
        // }, 4000);
      } else {
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to delete zone. Server error.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error during zone deletion:", error);
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Failed to delete zone. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setIdToRemove(null);
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickUsers = () => {
    setUsersOpen(!usersOpen);
  };

  // useEffect(() => {
  //   setIsVisible(true);
  // }, []);

  // Function to update site details after editing
  const handleUpdateZoneDetails = (updatedDetails) => {
    setZoneDetails(updatedDetails);
    setEditingZoneDetails(updatedDetails);
    setSnackbarSeverity("success");
    setSnackbarTitle("Success");
    setSnackbarMessage("Zone Details Updated Successfully");
    setSnackbarOpen(true);
    setCurrentPage(null);
    // setIsVisible(true);
  };

  const handleRefresh = (updatedDetails) => {
    setZoneDetails(updatedDetails);
    setEditingZoneDetails(updatedDetails);
    // setSnackbarSeverity("success");
    // setSnackbarTitle("Success");
    // setSnackbarMessage("");
    // setSnackbarOpen(true);
    // setCurrentPage(null);
    // setIsVisible(true);
  };

  // Function to set editing zone details and switch to edit mode
  const handleEditZone = () => {
    // setIsVisible(false);
    setEditingZoneDetails({
      site_id: siteId,
      zone_id: id,
      name: zoneDetails.name,
      description: zoneDetails.description,
      iot_device_id: zoneDetails.iot_device_id,
      data_pr_time: zoneDetails.data_pr_time,
      iot_device_secure_token: zoneDetails.iot_device_secure_token,
      devices_server_ip: zoneDetails.devices_server_ip,
      devices_server_port: zoneDetails.devices_server_port,
      battery_type: zoneDetails.battery_type,
      nominal_cell_vol: zoneDetails.nominal_cell_vol,
      cell_ov_thr: zoneDetails.cell_ov_thr,
      cell_uv_thr: zoneDetails.cell_uv_thr,
      battery_uv_thr: zoneDetails.battery_uv_thr,
      battery_ov_thr: zoneDetails.battery_ov_thr,
      cell_count: zoneDetails.cell_count,
      temperature_count: zoneDetails.temperature_count,
      temp_high_thr: zoneDetails.temp_high_thr,
      temp_low_thr: zoneDetails.temp_low_thr,
    });
    setCurrentPage("editZoneDetails");
  };

  const fetchZoneDetails = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/zone/${id}/details`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setZoneDetails(response.data.data);
      } else {
        console.error("Failed to fetch site details. Server error.");
      }
    } catch (error) {
      console.error("Error searching for sites:", error);
    }
  };

  const handleDelete = async () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/zone/${id}/delete`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle("SUCCESS");
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(`Warning : ${response.data.status}`);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        }
        // Update the UI by setting zoneDetails to null
        setZoneDetails(null);
        setIsZoneDeleted(true);

        // Notify the parent component to update the zones list
        onDelete(id);

        setTimeout(() => {
          onClose();
        }, 4000);
      } else {
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to delete zone. Server error.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error during zone deletion:", error);
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Failed to delete zone. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  useEffect(() => {
    if (!isZoneDeleted) {
      fetchZoneDetails();
    }
  }, [id, isZoneDeleted]);

  const handleAddUser = () => {
    // setZoneId(zoneDetails.id);
    // setZoneName(zoneDetails.name);
    setCurrentPage("addUser");
  };

  //Handle All Zone Users
  const handleAllZoneUsers = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/zone/${id}/user-list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setUsers(response.data.data.users);
        } else {
          console.error("No Zones found.");
        }
      } else {
        console.error("Failed to get all zones. Server error.");
      }
    } catch (error) {
      console.error("Error getting all zones:", error);
    }
  };

  useEffect(() => {
    // setIsVisible(true);
    handleAllZoneUsers();
  }, []);

  //   Styles
  const btnStyle = { my: "5px", py: "10px", mx: "7px", px: "15px" };
  const btnTextStyle = { fontWeight: "bold", fontSize: "14px" };

  return (
    <Box sx={{ mx: "auto" }}>
      {/* Dialog Box for Delete Site  */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Zone"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to
            <strong style={{ color: "grey" }}>&nbsp;Delete</strong> this zone?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              close
            </Typography>
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              delete
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Code For Response Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          <AlertTitle>
            <strong>{snackbarTitle.toUpperCase()}</strong>
          </AlertTitle>
          <Typography>{snackbarMessage.toUpperCase()}</Typography>
        </MuiAlert>
      </Snackbar>
      <Box
        component={Paper}
        sx={{
          mt: "30px",

          // opacity: showContent ? (isZoneDeleted ? 0 : 1) : 0,
          transition: "opacity 0.5s ease-in-out",
          transitionDelay: "0.1s",
          width: "100%",
        }}
      >
        {isZoneDeleted ? (
          <Box sx={{ m: 2, textAlign: "center" }}></Box>
        ) : currentPage === "editZoneDetails" ? (
          <EditZoneDetails
            onClose={() => setCurrentPage(null)}
            zoneDetails={editingZoneDetails}
            zoneId={zoneDetails.zone_id}
            onUpdate={handleUpdateZoneDetails}
            onRefresh={handleRefresh}
          />
        ) : currentPage === "addUser" ? (
          <SetZoneUser
            onClose={() => setCurrentPage(null)}
            zoneDetails={editingZoneDetails}
            zoneId={id}
            zoneName={zoneDetails.name}
            siteId={siteId}
            onUpdate={handleAllZoneUsers}
          />
        ) : zoneDetails ? (
          <Box sx={{ p: 2, m: 2, px: 3 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                my: "10px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  // textAlign: "center",
                  cursor: "default",
                  letterSpacing: "1px",
                  // color: "#a8720c",
                  color: "#00695c",
                  display: "flex",
                  justifyContent: "flex-start",
                  mt: "14px",
                  ml: "2px",
                }}
              >
                <InfoOutlined
                  fontSize="small"
                  sx={{ mt: "6px", mr: "1px", color: "#00695c" }}
                />
                <strong>{zoneDetails.name}</strong>
              </Typography>
              {/* Buttons  */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <ButtonGroup>
                  <Button
                    variant="contained"
                    sx={btnStyle}
                    onClick={handleAddUser}
                    id="add-button"
                    size="medium"
                    className={classes.addBtn}
                  >
                    <Typography sx={btnTextStyle}>Add User</Typography>
                    <PersonAddAlt1Sharp
                      sx={{ fontSize: "19px", mx: "5px", mb: "3px" }}
                    />
                  </Button>
                  <Button
                    variant="contained"
                    sx={btnStyle}
                    onClick={handleEditZone}
                    id="edit-button"
                    size="medium"
                    className={classes.editBtn}
                  >
                    <Typography sx={btnTextStyle}>Edit</Typography>
                    <Edit sx={{ fontSize: "17px", mx: "5px", mb: "2px" }} />
                  </Button>
                  <Button
                    variant="contained"
                    sx={btnStyle}
                    onClick={handleDelete}
                    id="delete-button"
                    size="medium"
                    className={classes.deleteBtn}
                  >
                    <Typography sx={btnTextStyle}>Delete</Typography>
                    <Delete sx={{ fontSize: "17px", mx: "5px", mb: "2px" }} />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
            <Box sx={{ overflow: "auto", height: "600px" }}>
              <List
                sx={{
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton>
                  <ListItemText primary="Zone ID" secondary={id} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Zone Name"
                    secondary={zoneDetails.name}
                  />
                </ListItemButton>

                <ListItemButton onClick={handleClickUsers}>
                  <ListItemText
                    sx={{ color: "#006251", fontWeight: "bold" }}
                    primary="Zone Users"
                  />
                  {usersOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={usersOpen} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      display: "grid",
                      justifyContent: "flex-start",
                      mb: "20px",
                    }}
                  >
                    {users.length > 0 ? (
                      <>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "center",
                            pt: "5px",
                          }}
                        >
                          USER/USERS
                        </Typography>
                        <div style={{ height: 320, width: "100%" }}>
                          <DataGrid
                            rows={users.map((userId, index) => ({
                              id: index + 1,
                              serialNumber: index + 1,
                              userId: userId,
                            }))}
                            columns={[
                              {
                                field: "serialNumber",
                                headerName: "#",
                                width: 80,
                              },
                              {
                                field: "userId",
                                headerName: "User ID",
                                width: 150,
                                editable: true,
                              },
                              {
                                field: "actions",
                                headerName: "Actions",
                                width: 150,
                                renderCell: (params) => (
                                  <div>
                                    <Button
                                      variant="contained"
                                      id="remove-btn"
                                      size="small"
                                      onClick={() =>
                                        handleRemoveUser(params.row.userId)
                                      }
                                      className={classes.deleteBtn}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Remove
                                      </Typography>
                                      <Delete
                                        sx={{ fontSize: "15px", mx: "5px" }}
                                      />
                                    </Button>
                                  </div>
                                ),
                              },
                            ]}
                            pageSizeOptions={[4, 10, 20, 50, 100]}
                            onSelectionModelChange={(newSelection) => {
                              setIdToRemove(newSelection[0] || null);
                            }}
                            getRowId={(row) => row.id}
                            sx={{ m: 1 }}
                            slots={{
                              toolbar: GridToolbarQuickFilter,
                              columnMenu: CustomColumnMenu,
                            }}
                          />
                        </div>
                        <Dialog
                          open={!!idToRemove}
                          onClose={() => setIdToRemove(null)}
                          maxWidth="xs"
                        >
                          <DialogTitle>Remove User</DialogTitle>
                          <DialogContent>
                            Are you sure you want to remove the user{" "}
                            <strong>#{idToRemove}</strong> from the site?
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setIdToRemove(null)}>
                              <Typography
                                sx={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Cancel
                              </Typography>
                            </Button>
                            <Button onClick={removeUser} color="error">
                              <Typography
                                sx={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Remove
                              </Typography>
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </>
                    ) : (
                      <Box sx={{ mt: "30px", width: "310px" }}>
                        <Typography
                          variant="body1"
                          sx={{ textAlign: "center" }}
                        >
                          No users are added here
                        </Typography>
                      </Box>
                    )}
                  </List>
                </Collapse>
                <ListItemButton onClick={handleClick}>
                  <ListItemText primary="Description" />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>
                        <Description />
                      </ListItemIcon>
                      <ListItemText
                        primary={zoneDetails.description}
                        sx={{ overflow: "auto" }}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItemButton>
                  <ListItemText
                    primary="IOT Device ID"
                    secondary={zoneDetails.iot_device_id}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Data PR Time"
                    secondary={zoneDetails.data_pr_time}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="IOT Device Secure Token"
                    secondary={zoneDetails.iot_device_secure_token}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Devices Server IP"
                    secondary={zoneDetails.devices_server_ip}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Devices Server Port"
                    secondary={zoneDetails.devices_server_port}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Battery Type"
                    secondary={zoneDetails.battery_type}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Nominal Cell Vol"
                    secondary={zoneDetails.nominal_cell_vol}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Cell OV Threshold"
                    secondary={zoneDetails.cell_ov_thr}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Cell UV Threshold"
                    secondary={zoneDetails.cell_uv_thr}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Battery UV Threshold"
                    secondary={zoneDetails.battery_uv_thr}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Battery OV Threshold"
                    secondary={zoneDetails.battery_ov_thr}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Cell Count"
                    secondary={zoneDetails.cell_count}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Temperature Count"
                    secondary={zoneDetails.temperature_count}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Temperature High Threshold"
                    secondary={zoneDetails.temp_high_thr}
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText
                    primary="Temperature Low Threshold"
                    secondary={zoneDetails.temp_low_thr}
                  />
                </ListItemButton>
              </List>
            </Box>
          </Box>
        ) : (
          <Box sx={{ mt: "30px" }}>
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Loading zone details...
            </Typography>
            <LinearProgress color="success" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ZoneDetails;
