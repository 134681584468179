import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Snackbar,
  CardHeader,
  CardActions,
  Box,
  LinearProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useAuth } from "../../bms-authentication/AuthContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import { HomeOutlined } from "@mui/icons-material";

const LinearWithValueLabel = () => {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            color="secondary"
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const UserManagement = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleHomeClick = async () => {
    try {
      await fetchPendingUsers();
    } finally {
      setIsRefreshing(false);
    }
  };

  //Fetch Pending Users
  const fetchPendingUsers = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/pending-users`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );
      setPendingUsers(response.data.data.users);
    } catch (err) {
      console.error("Error fetching user details:", err);
    }
  };

  useEffect(() => {
    fetchPendingUsers();
  }, [apiToken, userId]);

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await fetchPendingUsers();
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleCloseAlert = (e, reason) => {
    if (reason !== "clickaway") {
      setAlertOpen(false);
    }
  };

  const handleApprove = async (id) => {
    try {
      setIsLoading(true);
      const accessToken = apiToken;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/user/${id}/approve`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      // Remove the approved user from the state
      setPendingUsers((prevUsers) =>
        prevUsers.filter((user) => user.user_id !== id)
      );

      setAlertSeverity("info");
      setAlertMessage(response.data.message.toUpperCase());
      setAlertOpen(true);
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReject = async (id) => {
    try {
      setAlertSeverity("warning");
      setAlertMessage(`User ${id} Will be Rejected!!`);
      setAlertOpen(true);
    } catch (error) {
      console.error("Error rejecting user:", error);
    }
  };

  return (
    <Box component="div" sx={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
        >
          <Tooltip title="Go Home" arrow placement="bottom">
            <IconButton color="info" onClick={handleHomeClick}>
              <HomeOutlined
                style={{
                  cursor: "pointer",
                  transition: "transform 0.1s ease",
                  transform: isRefreshing ? "rotate(360deg)" : "rotate(0deg)",
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontSize: "25px",
            fontWeight: "bold",
            py: "5px",
            marginBottom: "15px",
          }}
        >
          USER MANAGEMENT
        </Typography>
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
        >
          {isRefreshing ? (
            // Display close icon while refreshing
            <IconButton color="error" onClick={() => setIsRefreshing(false)}>
              <CloseIcon style={{ cursor: "pointer" }} />
            </IconButton>
          ) : (
            <Tooltip title="Refresh" arrow placement="bottom">
              <IconButton color="info" onClick={handleRefresh}>
                <RefreshIcon
                  style={{
                    cursor: "pointer",
                    transition: "transform 0.1s ease",
                    transform: isRefreshing ? "rotate(360deg)" : "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {pendingUsers.length === 0 ? (
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontSize: "19px",
            fontWeight: "bold",
            marginBottom: "10px",
            marginTop: "30px",
            p: 3,
            color: "#000e77",
            cursor: "grabbing",
          }}
        >
          No Pending Users
        </Typography>
      ) : (
        <Box
          component="div"
          sx={{ display: "flex", flexWrap: "wrap", mx: "auto" }}
        >
          {pendingUsers.map((user) => (
            <Card
              elevation={7}
              sx={{
                maxWidth: 345,
                my: "10px",
                mx: "auto",
                textAlign: "center",
              }}
              key={user.user_id}
            >
              <CardHeader title={user.name.toUpperCase()} />
              <CardContent>
                <Typography variant="body1">
                  <strong>Id :</strong> {user.user_id}
                </Typography>
                <Typography variant="body1">
                  <strong>Name :</strong> {user.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Email :</strong> {user.email}
                </Typography>
              </CardContent>
              <CardActions>
                <Box>
                  {isLoading && (
                    <Box sx={{ textAlign: "center", width: "100%", my: 2 }}>
                      <LinearWithValueLabel
                        variant="buffer"
                        color="secondary"
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ textAlign: "center" }}
                      >
                        Approving...
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Button
                    onClick={() => handleApprove(user.user_id)}
                    variant="contained"
                    color="success"
                    sx={{ backgroundColor: "black", color: "white" }}
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={() => handleReject(user.user_id)}
                    variant="contained"
                    color="error"
                    sx={{ backgroundColor: "black", color: "white" }}
                  >
                    Reject
                  </Button>
                </Box>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}

      <Box component="div">
        {/* MUI Snackbar */}
        <Snackbar
          open={alertOpen}
          autoHideDuration={2000}
          onClose={handleCloseAlert}
          sx={{
            position: "absolute",
            top: "50px",
            left: "auto",
            right: "auto",
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={7}
            variant="filled"
            onClose={handleCloseAlert}
            severity={alertSeverity}
          >
            <AlertTitle>
              {alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}
            </AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default UserManagement;
