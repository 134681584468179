import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AdminPanelSettingsSharp from "@mui/icons-material/AdminPanelSettingsSharp";
import {
  ManageAccountsSharp as UserManagementIcon,
  ManageHistorySharp as SiteManagementIcon,
} from "@mui/icons-material";
import AppSettingsAltSharpIcon from "@mui/icons-material/AppSettingsAltSharp";
import {
  AlertTitle,
  Avatar,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
} from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import SiteManagement from "./bms-site-management/SiteManagement";
import UserManagement from "./bms-user-management/UserManagement";
import DeviceManagement from "./bms-device-management/DeviceManagement";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useAuth } from "../bms-authentication/AuthContext";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const drawerWidth = 150;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function ManagementIndex() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const settings = ["Profile", "Account", "Dashboard", "Logout"];
  const [value, setValue] = React.useState(0);
  const tabData = [
    {
      label: "User Management",
      icon: (
        <Tooltip title="User Management" arrow placement="right">
          <UserManagementIcon />
        </Tooltip>
      ),
    },
    {
      label: "Device Management",
      icon: (
        <Tooltip title="Device Management" arrow placement="right">
          <AppSettingsAltSharpIcon />
        </Tooltip>
      ),
    },
    {
      label: "Site Management",
      icon: (
        <Tooltip title="Site Management" arrow placement="right">
          <SiteManagementIcon />
        </Tooltip>
      ),
    },
  ];
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = React.useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = React.useState(false);
  const [dashboardDialogOpen, setDashboardDialogOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const { state, setApiToken } = useAuth();
  const { apiToken, userId } = state;
  const [adminName, setadminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminType, setAdminType] = useState("");
  const [adminStatus, setAdminStatus] = useState("");
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);

  const handleSuccessAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessAlertOpen(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  // Profile
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Tab Change
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Menu item click handler
  const handleMenuItemClick = async (menuItem) => {
    switch (menuItem) {
      case "Profile":
        handleOpenProfileDialog();
        break;
      case "Account":
        handleOpenAccountDialog();
        break;
      case "Dashboard":
        handleOpenDashboardDialog();
        break;
      case "Logout":
        setLogoutDialogOpen(true);
        break;
      default:
        alert(`${menuItem} clicked!`);
        break;
    }
    handleCloseUserMenu();
  };

  //Logout Function
  const handleLogout = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setApiToken(null);
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  // Function to open the profile dialog
  const handleOpenProfileDialog = () => {
    setProfileDialogOpen(true);
  };

  // Function to open the account dialog
  const handleOpenAccountDialog = () => {
    setAccountDialogOpen(true);
  };

  // Function to open the dashboard dialog
  const handleOpenDashboardDialog = () => {
    setDashboardDialogOpen(true);
  };

  // Fetching Current Admin Name
  const fetchUserDetails = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/user/${userId}/details`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );
      setadminName(response.data.data.name);
      setAdminEmail(response.data.data.email);
      setAdminStatus(response.data.data.approve_status);
      setAdminType(response.data.data.type);
    } catch (err) {
      console.error("Error fetching user details:", err);
    }
  };

  useEffect(() => {
    const tryAutoLogin = async () => {
      if (!apiToken) {
        // If there's no stored token, navigate to the login page
        navigate("/login");
      } else {
        // If there is a token, fetch user details and name
        await fetchUserDetails();
        setSuccessAlertOpen(true);
      }
    };

    tryAutoLogin();
  }, [apiToken, userId, navigate]);

  return (
    <Box component="div">
      {/* Successful login message alert */}
      <Snackbar
        open={successAlertOpen}
        autoHideDuration={2500}
        onClose={handleSuccessAlertClose}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleSuccessAlertClose}
          severity="success"
        >
          <AlertTitle>
            Hello{" "}
            <strong>
              {adminName.charAt(0).toUpperCase() + adminName.slice(1)}!
            </strong>
          </AlertTitle>
          Welcome to Admin Portal 
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* Logout Dialog Box */}
        <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hello! <strong>{adminName}</strong>,&nbsp;Are you sure you want to
            <strong style={{ color: "grey" }}> logout?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setLogoutDialogOpen(false);
              handleLogout();
            }}
            color="error"
            autoFocus
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      {/* Profile Dialog */}
      <Dialog
        open={profileDialogOpen}
        onClose={() => setProfileDialogOpen(false)}
        aria-labelledby="profile-dialog-title"
      >
        <DialogTitle id="profile-dialog-title">Profile Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Name: <strong>{adminName}</strong>
            <br />
            Email: <strong>{adminEmail}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProfileDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Account Dialog */}
      <Dialog
        open={accountDialogOpen}
        onClose={() => setAccountDialogOpen(false)}
        aria-labelledby="account-dialog-title"
      >
        <DialogTitle id="profile-dialog-title">Account Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ID: <strong>{userId}</strong>
            <br />
            Name: <strong>{adminName}</strong>
            <br />
            Email: <strong>{adminEmail}</strong>
            <br />
            Type: <strong>{adminType}</strong>
            <br />
            Status: <strong>{adminStatus}</strong>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAccountDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dashboard Dialog */}
      <Dialog
        open={dashboardDialogOpen}
        onClose={() => setDashboardDialogOpen(false)}
        aria-labelledby="dashboard-dialog-title"
      >
        <DialogContent>
          <DialogContentText sx={{ color: "red" }}>
            <strong>The Dashboard is empty</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDashboardDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <CssBaseline />
      <Box component="div">
        <AppBar position="fixed" open={open} color="grey">
          <Toolbar
            sx={{ display: "flex", justifyContent: "space-between", my: "8px" }}
          >
            <Box component="div">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleToggleDrawer}
                edge="end"
                sx={{
                  ...(open && { display: "none" }),
                }}
              >
                {open ? (
                  <CloseIcon />
                ) : (
                  <Tooltip title="Menu" arrow>
                    <MenuIcon />
                  </Tooltip>
                )}
              </IconButton>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
              }}
            >
              <AdminPanelSettingsSharp
                sx={{ fontSize: "29px", marginTop: "6px" }}
              />
              <Typography
                variant="h6"
                noWrap
                sx={{
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  letterSpacing: ".1rem",
                  color: "inherit",
                  fontSize: "28px",
                }}
              >
                ADMIN
              </Typography>
            </Box>
            <Box component="div">
              <Tooltip title="Open Settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        width: 29,
                        height: 29,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {adminName && adminName.charAt(0).toUpperCase()}
                    </Avatar>
                    <Typography
                      variant="subtitle1"
                      noWrap
                      sx={{
                        color: "inherit",
                        fontSize: "9px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {adminName && adminName.toUpperCase()}
                    </Typography>
                  </Box>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleMenuItemClick(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <Tooltip title="close">
                  <CloseIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Close" arrow placement="bottom">
                  <CloseIcon />
                </Tooltip>
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              paddingTop: 3,
              paddingBottom: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {tabData.map((tab, index) => (
              <Tab
                key={tab.label}
                label={open ? tab.label : null}
                icon={React.cloneElement(tab.icon, {
                  sx: {
                    fontSize: open ? "29px" : "39px",
                    color: index === value ? "#000000" : "#6b6b6b",
                    marginLeft: open ? "0px" : "15px",
                    marginRight: open ? "0px" : "15px",
                  },
                })}
                sx={{
                  color: index === value ? "inherit" : "#6b6b6b",
                  marginBottom: 1,
                  marginTop: 2,
                }}
              />
            ))}
          </Tabs>
        </Drawer>
      </Box>
      <Box component="div" sx={{ marginTop: "100px", marginLeft: "90px" }}>
        <Paper
          elevation={24}
          sx={{ p: 4, maxWidth: "100%", minWidth: "300px", width: "100%" }}
        >
          {value === 0 && <UserManagement />}
          {value === 1 && <DeviceManagement />}
          {value === 2 && <SiteManagement />}
        </Paper>
      </Box>
    </Box>
  );
}
