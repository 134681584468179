import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Snackbar,
  CardHeader,
  CardActions,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Radio,
  LinearProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useAuth } from "../../bms-authentication/AuthContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import VerifiedIcon from "@mui/icons-material/Verified";
import { HomeOutlined } from "@mui/icons-material";

const LinearWithValueLabel = () => {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            color="secondary"
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const DeviceManagement = () => {
  const [pendingDevices, setPendingDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserPendingDevices, setSelectedUserPendingDevices] = useState(
    []
  );
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [approvedDevices, setApprovedDevices] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenDialog = (userId, pendingDevices) => {
    setSelectedUserId(userId);
    setSelectedUserPendingDevices(pendingDevices);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedUserPendingDevices([]);
    setDialogOpen(false);
  };

  //Fetching Pending Devices
  const fetchPendingDevices = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/user/${userId}/pending-devices`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );
      setPendingDevices(response.data.data.users);
      // console.log(response.data.data.users);
    } catch (err) {
      console.error("Error fetching user details:", err);
    }
  };

  useEffect(() => {
    fetchPendingDevices();
  }, [apiToken, userId]);

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      setApprovedDevices([]);
      await fetchPendingDevices();
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleHomeClick = async () => {
    try {
      setIsRefreshing(true);
      setApprovedDevices([]);
      await fetchPendingDevices();
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleCloseAlert = (e, reason) => {
    if (reason !== "clickaway") {
      setAlertOpen(false);
    }
  };

  const handleCheckboxChange = (value) => {
    setSelectedDevice(value);
  };

  const handleApproveReject = async (action) => {
    try {
      setIsLoading(true);
      const accessToken = apiToken;

      if (action === "approve" && !selectedDevice) {
        setAlertSeverity("warning");
        setAlertMessage("Select a device to approve");
        setAlertOpen(true);
        return;
      }

      if (action === "reject" && !selectedDevice) {
        setAlertSeverity("warning");
        setAlertMessage("Select a device to reject");
        setAlertOpen(true);
        return;
      }

      if (action === "reject") {
        setAlertSeverity("warning");
        setAlertMessage(
          `Device(${selectedDevice}) of Id(${selectedUserId}) Will be Rejected!!`
        );
        setAlertOpen(true);
        setSelectedDevice(null);
        handleCloseDialog();
        return;
      }

      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/user/${selectedUserId}/${action}-pending-device`,
        { target_device_id: selectedDevice },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      // Handle the success message from the API
      setAlertSeverity("info");
      setAlertMessage(response.data.message.toUpperCase());
      setAlertOpen(true);

      // Update approved devices list
      setApprovedDevices((prevDevices) => [...prevDevices, selectedDevice]);

      // Remove the approved device immediately
      setPendingDevices((prevPendingDevices) =>
        prevPendingDevices.filter(
          (pendingDevice) =>
            !(
              pendingDevice.user_id === selectedUserId &&
              pendingDevice.target_device_id === selectedDevice
            )
        )
      );
    } catch (error) {
      console.error("Error processing approval or rejection:", error);
      setAlertSeverity("error");
      setAlertMessage("An error occurred");
      setAlertOpen(true);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box component="div">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
        >
          <Tooltip title="Go Home" arrow placement="bottom">
            <IconButton color="info" onClick={handleHomeClick}>
              <HomeOutlined
                style={{
                  cursor: "pointer",
                  transition: "transform 0.1s ease",
                  transform: isRefreshing ? "rotate(360deg)" : "rotate(0deg)",
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontSize: "25px",
            fontWeight: "bold",
            py: "5px",
            marginBottom: "15px",
          }}
        >
          DEVICE MANAGEMENT
        </Typography>
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
        >
          {isRefreshing ? (
            <IconButton color="error" onClick={() => setIsRefreshing(false)}>
              <CloseIcon style={{ cursor: "pointer" }} />
            </IconButton>
          ) : (
            <Tooltip title="Refresh" arrow placement="bottom">
              <IconButton color="info" onClick={handleRefresh}>
                <RefreshIcon
                  style={{
                    cursor: "pointer",
                    transition: "transform 0.1s ease",
                    transform: isRefreshing ? "rotate(360deg)" : "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {pendingDevices.length === 0 ? (
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontSize: "19px",
            fontWeight: "bold",
            marginBottom: "10px",
            marginTop: "30px",
            p: 3,
            color: "#000e77",
            cursor: "grabbing",
          }}
        >
          No Pending Devices
        </Typography>
      ) : (
        <Box
          component="div"
          sx={{ display: "flex", flexWrap: "wrap", mx: "auto" }}
        >
          {pendingDevices.map((device) => (
            <Card
              elevation={7}
              sx={{
                maxWidth: 345,
                my: "10px",
                mx: "auto",
                textAlign: "center",
              }}
              key={device.user_id}
            >
              <CardHeader
                title={<strong>{device.name.toUpperCase()}</strong>}
              />
              <CardContent>
                <Typography variant="body1">
                  <strong>Id :</strong> {device.user_id}
                </Typography>
                <Typography variant="body1">
                  <strong>Name :</strong> {device.name}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() =>
                    handleOpenDialog(device.user_id, device.pending_devices)
                  }
                  variant="contained"
                  color="secondary"
                  sx={{
                    my: "15px",
                    mx: "10px",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  Check Pending Devices
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}
      {/* Dialog Box For Pending Devices List  */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ textAlign: "center" }}>
          <strong>Pending Devices</strong>
        </DialogTitle>
        <DialogContent sx={{ maxWidth: 445, my: "10px", mx: "auto" }}>
          <Typography variant="body1">
            <strong>User Id :</strong> {selectedUserId}
          </Typography>
          <List>
            <Typography variant="body1">
              <strong>Device/Devices :</strong>
            </Typography>
            {selectedUserPendingDevices.map((deviceString) => {
              try {
                // Assuming deviceString is in the format ['{1522cf0b1sgfhs78,2shs454d56c523c}']
                const devicesArray = deviceString
                  .replace(/['{}]/g, "") // Remove single quotes and curly braces
                  .split(","); // Split the string into an array based on commas

                return devicesArray.map((device) => {
                  const isDeviceSelected = selectedDevice === device;
                  const isDeviceApproved = approvedDevices.includes(device);

                  return (
                    <div key={device}>
                      <li>
                        <Radio
                          checked={isDeviceSelected}
                          onChange={() => handleCheckboxChange(device)}
                          disabled={isDeviceApproved}
                        />
                        {device}
                        {isDeviceApproved && (
                          <span
                            style={{
                              marginLeft: "8px",
                              color: "green",
                              fontSize: "12px",
                              marginBottom: "5px",
                            }}
                          >
                            Approved
                            <VerifiedIcon
                              fontSize="small"
                              color="success"
                              sx={{ paddingTop: "6px" }}
                            />
                          </span>
                        )}
                      </li>
                    </div>
                  );
                });
              } catch (error) {
                console.error("Error processing device string:", error);
                return null;
              }
            })}
          </List>
          <br />
          {isLoading && (
            <Box sx={{ textAlign: "center", width: "100%", my: 2 }}>
              <LinearWithValueLabel />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                Approving...
              </Typography>
            </Box>
          )}
          {/* Approve and Reject buttons */}
          <Button
            onClick={() => handleApproveReject("approve")}
            variant="contained"
            color="success"
            sx={{ margin: "5px", backgroundColor: "black", color: "white" }}
          >
            Approve
          </Button>
          <Button
            onClick={() => handleApproveReject("reject")}
            variant="contained"
            color="error"
            sx={{ margin: "5px", backgroundColor: "black", color: "white" }}
          >
            Reject
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Box component="div">
        {/* MUI Snackbar for Alerts */}
        <Snackbar
          open={alertOpen}
          autoHideDuration={2000}
          onClose={handleCloseAlert}
          sx={{
            position: "absolute",
            top: "50px",
            left: "auto",
            right: "auto",
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={7}
            variant="filled"
            onClose={handleCloseAlert}
            severity={alertSeverity}
          >
            <AlertTitle>
              {alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}
            </AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default DeviceManagement;
