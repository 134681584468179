import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import {
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Close, InfoSharp } from "@mui/icons-material";
import {
  DataGrid,
  GridColumnMenu,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    background: 'linear-gradient(90deg, #000000 0%, #130036 100%)',
    transition: 'background-color 0.1s ease-in-out !important',
    '&:hover': {
      background: 'linear-gradient(90deg, #000000 0%, #860000 100%)',
      transition: 'background-color 0.3s ease !important',
    },
  },
}));

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuFilterItem: null,
      }}
    />
  );
}

const ShowSiteUsers = ({ siteId, siteName, onClose }) => {
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [isVisible, setIsVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [idToRemove, setIdToRemove] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const classes = useStyles();
  const handleRemoveUser = (user_id) => {
    setIdToRemove(user_id);
  };

  const removeUser = async () => {
    try {
      const accessToken = apiToken;
      const user_id = idToRemove;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/delete-user`,
        { user_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle("SUCCESS");
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          handleAllSiteUsers();
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(`Warning `);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
        }
        // setTimeout(() => {
        //   onClose();
        // }, 4000);
      } else {
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to delete zone. Server error.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error during zone deletion:", error);
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Failed to delete zone. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setIdToRemove(null);
    }
  };

  const handleAllSiteUsers = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/user-list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setUsers(response.data.data.users);
        } else {
          console.error("No Zones found.");
        }
      } else {
        console.error("Failed to get all zones. Server error.");
      }
    } catch (error) {
      console.error("Error getting all zones:", error);
    }
  };

  useEffect(() => {
    setIsVisible(true);
    handleAllSiteUsers();
  }, []);

  return (
    <Box
      component={Paper}
      elevation={24}
      sx={{
        maxWidth: "100%",
        minWidth: "100%",
        height: "714px",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s ease-in-out",
        transitionDelay: "0.1s",
        p: 2,
        mx: "auto",
        px: "auto",
        m: 0,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Alert Code For Response Message */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          sx={{
            position: "absolute",
            top: "50px",
            left: "auto",
            right: "auto",
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            elevation={7}
            variant="filled"
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
          >
            <AlertTitle>
              <strong>{snackbarTitle.toUpperCase()}</strong>
            </AlertTitle>
            <Typography>{snackbarMessage.toUpperCase()}</Typography>
            <Typography>
              {/* User with ID <strong>{idToRemove}</strong> from the site{" "}
              <strong>{siteId}</strong> has been successfully deleted. */}
            </Typography>
          </MuiAlert>
        </Snackbar>
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            p: 1,
            color: "#00695c",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              p: 1,
              pb: "5px",
              pt: "0px",
              cursor: "default",
              textDecoration: "underline",
              letterSpacing: "1px",
              color: "#00695c",
            }}
          >
            <strong>
              {siteName}/{siteId}
            </strong>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
          <Tooltip title="Close" arrow placement="right">
            <IconButton onClick={onClose}>
              <Close sx={{ color: "#00695c" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {users.length > 0 ? (
        <>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "center",
              mb: "10px",
              pb: "10px",
              pt: "5px",
            }}
          >
            USER/USERS
          </Typography>
          <div style={{ height: 480, width: "100%" }}>
            <DataGrid
              rows={users.map((userId, index) => ({
                id: index + 1,
                serialNumber: index + 1,
                userId: userId,
              }))}
              columns={[
                { field: "serialNumber", headerName: "#", width: 80 },
                {
                  field: "userId",
                  headerName: "User ID",
                  width: 150,
                  editable: true,
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  width: 150,
                  renderCell: (params) => (
                    <div>
                      <Button
                        variant="contained"
                        id="remove-btn"
                        size="small"
                        onClick={() => handleRemoveUser(params.row.userId)}
                        className={classes.deleteBtn}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            
                          }}
                        >
                          Remove
                        </Typography>
                        <InfoSharp sx={{ fontSize: "15px", mx: "5px" }} />
                      </Button>
                    </div>
                  ),
                },
              ]}
              pageSizeOptions={[4, 10, 20, 50, 100]}
              onSelectionModelChange={(newSelection) => {
                setIdToRemove(newSelection[0] || null);
              }}
              getRowId={(row) => row.id}
              sx={{ m: 1 }}
              slots={{
                toolbar: GridToolbarQuickFilter,
                columnMenu: CustomColumnMenu,
              }}
            />
          </div>
          <Dialog
            open={!!idToRemove}
            onClose={() => setIdToRemove(null)}
            maxWidth="xs"
          >
            <DialogTitle>Remove User</DialogTitle>
            <DialogContent>
              Are you sure you want to remove the user{" "}
              <strong>#{idToRemove}</strong> from the site?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIdToRemove(null)}>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Cancel
                </Typography>
              </Button>
              <Button onClick={removeUser} color="error">
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Remove
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Box sx={{ mt: "30px", width: "310px" }}>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            No users are added here
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ShowSiteUsers;
