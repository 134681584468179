import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  ButtonGroup,
  Paper,
  TextField,
  Snackbar,
  AlertTitle,
  Tooltip,
  Typography,
  InputAdornment,
  Zoom,
} from "@mui/material";
import {
  Close,
  EditNoteSharp,
  LabelImportantSharp,
  Save,
} from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    background: "linear-gradient(90deg, #000000 0%, #130036 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #000000 0%, #004e00 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
}));

const EditZoneDetails = ({ zoneDetails, onUpdate, onClose }) => {
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const validateRange = (min, max) => ({
    name: "range",
    exclusive: false, // Ensures that the value can be equal to the min or max
    message: `Value should be between ${min} and ${max}`,
    test: (value) => {
      return value >= min && value <= max;
    },
  });

  const isIPAddressValid = (ip) => {
    const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return ipRegex.test(ip);
  };

  //formik validation for edit zone
  const formik = useFormik({
    initialValues: {
      // zone_id: zoneDetails.zone_id,
      name: zoneDetails.name,
      description: zoneDetails.description,
      iot_device_id: zoneDetails.iot_device_id,
      data_pr_time: zoneDetails.data_pr_time,
      iot_device_secure_token: zoneDetails.iot_device_secure_token,
      devices_server_ip: zoneDetails.devices_server_ip,
      devices_server_port: zoneDetails.devices_server_port,
      battery_type: zoneDetails.battery_type,
      nominal_cell_vol: zoneDetails.nominal_cell_vol,
      cell_ov_thr: zoneDetails.cell_ov_thr,
      cell_uv_thr: zoneDetails.cell_uv_thr,
      battery_uv_thr: zoneDetails.battery_uv_thr,
      battery_ov_thr: zoneDetails.battery_ov_thr,
      cell_count: zoneDetails.cell_count,
      temperature_count: zoneDetails.temperature_count,
      temp_high_thr: zoneDetails.temp_high_thr,
      temp_low_thr: zoneDetails.temp_low_thr,
    },
    validationSchema: Yup.object({
      // zone_id: Yup.number().required("Zone Id Required").test(validateRange(1, 32768)),
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      iot_device_id: Yup.string()
        .required("IoT Device ID is required")
        .test(validateRange(1, 32768)),
      data_pr_time: Yup.string()
        .required("Data PR Time is required")
        .test(validateRange(1, 32768)),
      iot_device_secure_token: Yup.string().required(
        "IoT Device Secure Token is required"
      ),
      devices_server_ip: Yup.string()
        .required("Devices Server IP is required")
        .test("valid-ip", "Invalid IP address", (value) => {
          return isIPAddressValid(value);
        }),
      devices_server_port: Yup.string()
        .required("Devices Server Port is required")
        .test(validateRange(1, 32768)),
      battery_type: Yup.string().required("Battery Type is required"),
      nominal_cell_vol: Yup.string().required("Nominal Cell Vol is required"),
      cell_ov_thr: Yup.string().required("Cell OV Threshold is required"),
      cell_uv_thr: Yup.string().required("Cell UV Threshold is required"),
      battery_uv_thr: Yup.string().required("Battery UV Threshold is required"),
      battery_ov_thr: Yup.string().required("Battery OV Threshold is required"),
      cell_count: Yup.string()
        .required("Cell Count is required")
        .test(validateRange(1, 32768)),
      temperature_count: Yup.string()
        .required("Temperature Count is required")
        .test(validateRange(1, 32768)),
      temp_high_thr: Yup.string().required(
        "Temperature High Threshold is required"
      ),
      temp_low_thr: Yup.string().required(
        "Temperature Low Threshold is required"
      ),
    }),
    onSubmit: async (values) => {
      const updatedValues = {
        ...values,
      };

      await handleUpdateZone(updatedValues);
      onUpdate(updatedValues);
      formik.resetForm();
    },
  });

  // Function to update site details
  const handleUpdateZone = async (values) => {
    const integerFields = [
      // "zone_id",
      "iot_device_id",
      "data_pr_time",
      "devices_server_port",
      "cell_count",
      "temperature_count",
    ];

    integerFields.forEach((field) => {
      values[field] = parseInt(values[field], 10);
    });

    // Convert specific fields to floats
    const floatFields = [
      "nominal_cell_vol",
      "cell_ov_thr",
      "cell_uv_thr",
      "battery_uv_thr",
      "battery_ov_thr",
      "temp_high_thr",
      "temp_low_thr",
    ];

    floatFields.forEach((field) => {
      values[field] = parseFloat(values[field]);
    });
    try {
      const accessToken = apiToken;
      const site_id = zoneDetails.site_id;
      const zone_id = zoneDetails.zone_id;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/site/${site_id}/zone/${zone_id}/update`,

        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle("Success");
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          onUpdate(values);
          console.log("Zone updated successfully.");
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(`${response.data.status}`);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          console.log("Zone not updated");
          console.log("Response Status : ", response.data.status);
          console.log("Response Message : ", response.data.message);
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          console.log("Zone not updated.");
        }
      } else {
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to add zone. Server error.");
        setSnackbarOpen(true);
        console.error("Failed to add zone. Server error.");
      }
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Error adding zone.");
      setSnackbarOpen(true);
      console.error("Error adding zone:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      component={Paper}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.4s ease-in-out",
        transitionDelay: "0s",
      }}
    >
      {/* Alert Code For Response Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          <AlertTitle>
            <strong>{snackbarTitle.toUpperCase()}</strong>
          </AlertTitle>
          {snackbarMessage.toUpperCase()}
        </MuiAlert>
      </Snackbar>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            my: "10px",
            p: 2,
            m: 2,
            px: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              cursor: "default",
              letterSpacing: "1px",
              color: "#00695c",
              display: "flex",
              justifyContent: "flex-start",
              mt: "10px",
            }}
          >
            <EditNoteSharp sx={{ mt: "4px", color: "#00695c" }} />
            <strong>{zoneDetails.name}</strong>
          </Typography>
          {/* Buttons  */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <ButtonGroup>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                size="small"
                sx={{ padding: "11px", backgroundColor: "black", mr: "3px" }}
                className={classes.addBtn}
              >
                <Save
                  fontSize="small"
                  sx={{ marginBottom: "3px", mx: "3px", mt: "2px" }}
                />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    mx: "2px",
                    p: "1px",
                  }}
                >
                  Update Changes
                </Typography>
              </Button>
              <Tooltip title="Close" arrow placement="right">
                <Button
                  onClick={onClose}
                  variant="outlined"
                  color="error"
                  size="small"
                >
                  <Close
                    fontSize="small"
                    sx={{ marginBottom: "3px", mx: "3px", mt: "2px" }}
                  />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mx: "20px",
            mb: "10px",
            px: "25px",
            pb: "30px",
            overflow: "auto",
            height: "600px",
          }}
        >
          <Tooltip
            title="You can't change the ID"
            arrow
            TransitionComponent={Zoom}
            followCursor
            placement="top"
          >
            <TextField
              label="Zone ID"
              variant="standard"
              fullWidth
              id="zone_id"
              name="zone_id"
              margin="dense"
              type="text"
              placeholder="Zone ID"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LabelImportantSharp />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              value={zoneDetails.zone_id}
            />
          </Tooltip>
          <TextField
            label="Zone Name"
            variant="standard"
            fullWidth
            id="name"
            name="name"
            margin="dense"
            type="text"
            placeholder="Zone Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            label="Description"
            variant="standard"
            fullWidth
            id="description"
            name="description"
            margin="dense"
            type="text"
            placeholder="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <TextField
            label="iot-device-id"
            variant="standard"
            fullWidth
            id="iot_device_id"
            name="iot_device_id"
            margin="dense"
            type="text"
            placeholder="iot-device-id : [int]"
            value={formik.values.iot_device_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.iot_device_id &&
              Boolean(formik.errors.iot_device_id)
            }
            helperText={
              formik.touched.iot_device_id && formik.errors.iot_device_id
            }
          />
          <TextField
            label="data-pr-time"
            variant="standard"
            fullWidth
            id="data_pr_time"
            name="data_pr_time"
            margin="dense"
            type="text"
            placeholder="data-pr-time : [int]"
            value={formik.values.data_pr_time}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.data_pr_time && Boolean(formik.errors.data_pr_time)
            }
            helperText={
              formik.touched.data_pr_time && formik.errors.data_pr_time
            }
          />
          <TextField
            label="iot-device-secure-token"
            variant="standard"
            fullWidth
            id="iot_device_secure_token"
            name="iot_device_secure_token"
            margin="dense"
            type="text"
            placeholder="secure-token : [string]"
            value={formik.values.iot_device_secure_token}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.iot_device_secure_token &&
              Boolean(formik.errors.iot_device_secure_token)
            }
            helperText={
              formik.touched.iot_device_secure_token &&
              formik.errors.iot_device_secure_token
            }
          />
          <TextField
            label="devices-server-ip"
            variant="standard"
            fullWidth
            id="devices_server_ip"
            name="devices_server_ip"
            margin="dense"
            type="text"
            placeholder="devices-server-ip"
            value={formik.values.devices_server_ip}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.devices_server_ip &&
              Boolean(formik.errors.devices_server_ip)
            }
            helperText={
              formik.touched.devices_server_ip &&
              formik.errors.devices_server_ip
            }
          />
          <TextField
            label="devices-server-port"
            variant="standard"
            fullWidth
            id="devices_server_port"
            name="devices_server_port"
            margin="dense"
            type="text"
            placeholder="server-port : [int]"
            value={formik.values.devices_server_port}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.devices_server_port &&
              Boolean(formik.errors.devices_server_port)
            }
            helperText={
              formik.touched.devices_server_port &&
              formik.errors.devices_server_port
            }
          />
          <TextField
            label="battery-type"
            variant="standard"
            fullWidth
            id="battery_type"
            name="battery_type"
            margin="dense"
            type="text"
            placeholder="battery-type"
            value={formik.values.battery_type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.battery_type && Boolean(formik.errors.battery_type)
            }
            helperText={
              formik.touched.battery_type && formik.errors.battery_type
            }
          />
          <TextField
            label="nominal-cell-vol"
            variant="standard"
            fullWidth
            id="nominal_cell_vol"
            name="nominal_cell_vol"
            margin="dense"
            type="text"
            placeholder="nominal-cell-vol : [float]"
            value={formik.values.nominal_cell_vol}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.nominal_cell_vol &&
              Boolean(formik.errors.nominal_cell_vol)
            }
            helperText={
              formik.touched.nominal_cell_vol && formik.errors.nominal_cell_vol
            }
          />
          <TextField
            label="cell-ov-threshold"
            variant="standard"
            fullWidth
            id="cell_ov_thr"
            name="cell_ov_thr"
            margin="dense"
            type="text"
            placeholder="cell-ov-thr : [float]"
            value={formik.values.cell_ov_thr}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.cell_ov_thr && Boolean(formik.errors.cell_ov_thr)
            }
            helperText={formik.touched.cell_ov_thr && formik.errors.cell_ov_thr}
          />
          <TextField
            label="cell-uv-threshold"
            variant="standard"
            fullWidth
            id="cell_uv_thr"
            name="cell_uv_thr"
            margin="dense"
            type="text"
            placeholder="cell-uv-thr : [float]"
            value={formik.values.cell_uv_thr}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.cell_uv_thr && Boolean(formik.errors.cell_uv_thr)
            }
            helperText={formik.touched.cell_uv_thr && formik.errors.cell_uv_thr}
          />
          <TextField
            label="battery-uv-threshold"
            variant="standard"
            fullWidth
            id="battery_uv_thr"
            name="battery_uv_thr"
            margin="dense"
            type="text"
            placeholder="battery-uv-thr : [float]"
            value={formik.values.battery_uv_thr}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.battery_uv_thr &&
              Boolean(formik.errors.battery_uv_thr)
            }
            helperText={
              formik.touched.battery_uv_thr && formik.errors.battery_uv_thr
            }
          />
          <TextField
            label="battery-ov-threshold"
            variant="standard"
            fullWidth
            id="battery_ov_thr"
            name="battery_ov_thr"
            margin="dense"
            type="text"
            placeholder="battery-ov-thr : [float]"
            value={formik.values.battery_ov_thr}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.battery_ov_thr &&
              Boolean(formik.errors.battery_ov_thr)
            }
            helperText={
              formik.touched.battery_ov_thr && formik.errors.battery_ov_thr
            }
          />
          <TextField
            label="cell-count"
            variant="standard"
            fullWidth
            id="cell_count"
            name="cell_count"
            margin="dense"
            type="text"
            placeholder="cell-count : [int}"
            value={formik.values.cell_count}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.cell_count && Boolean(formik.errors.cell_count)
            }
            helperText={formik.touched.cell_count && formik.errors.cell_count}
          />
          <TextField
            label="temperature-count "
            variant="standard"
            fullWidth
            id="temperature_count"
            name="temperature_count"
            margin="dense"
            type="text"
            placeholder="temp-count : [int]"
            value={formik.values.temperature_count}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.temperature_count &&
              Boolean(formik.errors.temperature_count)
            }
            helperText={
              formik.touched.temperature_count &&
              formik.errors.temperature_count
            }
          />
          <TextField
            label="temp-high-threshold"
            variant="standard"
            fullWidth
            id="temp_high_thr"
            name="temp_high_thr"
            margin="dense"
            type="text"
            placeholder="temp-high-thr : [float]"
            value={formik.values.temp_high_thr}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.temp_high_thr &&
              Boolean(formik.errors.temp_high_thr)
            }
            helperText={
              formik.touched.temp_high_thr && formik.errors.temp_high_thr
            }
          />
          <TextField
            label="temp-low-threshold"
            variant="standard"
            fullWidth
            id="temp_low_thr"
            name="temp_low_thr"
            margin="dense"
            type="text"
            placeholder="temp-low-thr : [float]"
            value={formik.values.temp_low_thr}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.temp_low_thr && Boolean(formik.errors.temp_low_thr)
            }
            helperText={
              formik.touched.temp_low_thr && formik.errors.temp_low_thr
            }
          />
        </Box>
      </form>
    </Box>
  );
};

export default EditZoneDetails;
