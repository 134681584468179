import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Avatar,
  Paper,
  Snackbar,
  AlertTitle,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import FolderIcon from "@mui/icons-material/Folder";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import { grey } from "@mui/material/colors";
import axios from "axios";
import { useAuth } from "../../bms-authentication/AuthContext";
import { Close, Edit, Update } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  updateSiteBtn: {
    background: "linear-gradient(90deg, #14003a 0%, #450047 100%)",
    transition: "background-color 0.1s ease-in-out !important",
    "&:hover": {
      background: "linear-gradient(90deg, #34003b 0%, #1e0057 100%)",
      transition: "background-color 0.3s ease !important",
    },
  },
}));

const EditSiteDetails = ({ siteDetails, siteId, onClose, onUpdate }) => {
  const { state } = useAuth();
  const { apiToken, userId } = state;
  const [isVisible, setIsVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      id: siteDetails.id,
      name: siteDetails.name,
      short_name: siteDetails.short_name,
      address: siteDetails.address,
      contact_no: siteDetails.contact_no,
      description: siteDetails.description,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      short_name: Yup.string().required("Short Name is required"),
      address: Yup.string().required("Address is required"),
      contact_no: Yup.number().required("Contact No is required"),
      description: Yup.string().required("Description Required"),
    }),
    onSubmit: async (values) => {
      const updatedValues = {
        ...values,
        site_id: siteId,
      };

      await handleUpdateSite(updatedValues);
      formik.resetForm();
    },
  });

  // Function to update site details
  const handleUpdateSite = async (values) => {
    try {
      const accessToken = apiToken;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/site/${siteId}/update`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status === "ok") {
          setSnackbarSeverity("success");
          setSnackbarTitle(`Success : ${response.data.status}`);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          console.log(response.data.message);
        } else if (response.data.status === "fault") {
          setSnackbarSeverity("warning");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(`Warning : ${response.data.status}`);
          setSnackbarOpen(true);
          console.log(response.data.message);
        } else {
          setSnackbarSeverity("info");
          setSnackbarTitle(response.data.status);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          console.log(response.data.message);
        }
      } else {
        console.error("Failed to add site. Server error.");
        setSnackbarSeverity("error");
        setSnackbarTitle("Error");
        setSnackbarMessage("Failed to add zone. Server error.");
        setSnackbarOpen(true);
      }
      setSnackbarOpen(true);
      setIsVisible(true);
    } catch (error) {
      console.error("Error adding site:", error);
      setSnackbarSeverity("error");
      setSnackbarTitle("Error");
      setSnackbarMessage("Error adding zone.");
      setSnackbarOpen(true);
    }
    onUpdate(values);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //Styles
  const fieldStyle = { marginTop: "5px", marginBottom: "18px" };
  const IconStyle = {
    fontSize: "23px",
    color: "black",
  };

  return (
    <Box
      component={Paper}
      // variant="outlined"
      elevation={24}
      sx={{
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
        transitionDelay: "0.1s",
      }}
    >
      {/* Alert Code For Response Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          <AlertTitle>
            <strong>{snackbarTitle.toUpperCase()}</strong>
          </AlertTitle>
          {snackbarMessage.toUpperCase()}
        </MuiAlert>
      </Snackbar>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            // textAlign: "center",
            p: 2,
            ml: "10px",
            mt: "2px",
            cursor: "default",
            textDecoration: "underline",
            letterSpacing: "1px",
            // color: "#a8720c",
            color: "#00695c",
          }}
        >
          <strong>
            {siteDetails.name}/{siteId}
          </strong>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
          <Tooltip title="Close" arrow placement="right">
            <IconButton onClick={onClose}>
              <Close sx={{ color: "#00695c" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          px: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          mx: "10px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", mt: "30px" }}>
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: grey[900],
              width: "18px",
              height: "18px",
              marginTop: "16px",
              mx: "5px",
            }}
          >
            <Edit fontSize="small" sx={{ p: "3px", m: "2px" }} />
          </Avatar>
          <Typography
            fontSize={"21px"}
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              my: "10px",
              mx: "1px",
            }}
          >
            EDIT SITE DETAILS
          </Typography>
        </Box>
        <Box
          sx={{
            width: "290px",
            overflow: "auto",
            height: "565px",
            px: "15px",
            // pt: "15px",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            {/* Hidden Fields  */}
            <TextField
              type="hidden"
              name="site_id"
              value={siteId}
              onChange={() => {}}
              onBlur={() => {}}
              sx={{
                display: "none",
                visibility: "hidden",
                opacity: 0,
              }}
            />
            <TextField
              label="Name"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              sx={fieldStyle}
              margin="dense"
              type="text"
              placeholder="Update Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FolderIcon sx={IconStyle} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              label="Short Name"
              variant="standard"
              fullWidth
              id="short_name"
              name="short_name"
              margin="dense"
              type="text"
              placeholder="Update Short Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FolderCopyIcon sx={IconStyle} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
              value={formik.values.short_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.short_name && Boolean(formik.errors.short_name)
              }
              helperText={formik.touched.short_name && formik.errors.short_name}
              sx={fieldStyle}
            />
            <TextField
              label="Address"
              variant="standard"
              fullWidth
              id="address"
              name="address"
              margin="dense"
              type="text"
              placeholder="Update Address"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceIcon sx={IconStyle} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              sx={fieldStyle}
            />
            <TextField
              label="Contact No"
              variant="standard"
              fullWidth
              id="contact_no"
              name="contact_no"
              margin="dense"
              type="text"
              placeholder="Update Contact Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon sx={IconStyle} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
              value={formik.values.contact_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contact_no && Boolean(formik.errors.contact_no)
              }
              helperText={formik.touched.contact_no && formik.errors.contact_no}
              sx={fieldStyle}
            />
            <TextField
              label="Description"
              variant="standard"
              fullWidth
              id="description"
              multiline
              maxRows={4}
              name="description"
              margin="dense"
              type="text"
              placeholder="Update Description"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DescriptionIcon sx={IconStyle} />
                  </InputAdornment>
                ),
              }}
              color="secondary"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              sx={fieldStyle}
            />
            <Box sx={{ marginTop: "28px", marginBottom: "25px" }}>
              <Button
              className={classes.updateSiteBtn}
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ padding: "10px", backgroundColor: "black" }}
              >
                <Update

                  sx={{fontWeight:"bold",fontSize:"19px", marginBottom: "2px", mx: "1px" }}
                />
                <Typography sx={{fontWeight:"550", fontSize: "15px", mx: "3px" }}>
                  Update Changes
                </Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default EditSiteDetails;
